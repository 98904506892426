import React from "react";

export const Column = ({ children, w, jc }) => {
  return (
    <div
      dir="ltr"
      style={{
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: jc ? jc : "flex-start",
        width: w ? w : "auto",
        flexShrink: 0,
        padding: "3px",
        margin: "0px 3px",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const Row = ({ children, w, jc, p }) => {
  return (
    <div
      dir="ltr"
      style={{
        position: "relative",
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: jc ? jc : "flex-start",
        width: w ? w : "auto",
        padding: p ? p : 2,
      }}
    >
      {children}
    </div>
  );
};
