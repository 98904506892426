import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";

import BlocklyView from "../blockly/BlocklyView";
import EditWindow from "../animate/EditWindow";
import ObjectsProps from "./ObjectsProps";
import FooterBar from "./FooterBar";

import ThumbImg from "./ThumbImg";
import ShapesObjectsModal from "../project/ShapesObjectsModal";
import TextObjectsModal from "../project/TextObjectsModal";
import ImgObjectsModal from "../project/ImgObjectsModal";
import LoadingPaper from "../tools/loadingPaper";
import { getUrlParams, goHomePage } from "../tools/browserHelpers";

import ProjectAPI from "../api/projectApi";
import {
  checkValidProject,
  extractProject,
  getProjectThumb,
} from "../project/ProjectOperations";
import { ReadLocalProject, WriteLocalProject } from "../data/LocalStorage";

import { useSelector, useDispatch } from "react-redux";
import { loadProject, setProjectThumb } from "../data/project";
import { setActiveObject } from "../data/control";
import { setActiveTask } from "../data/editor";

const Designer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((s) => s.settings);
  const project = useSelector((s) => s.project);
  const { activeTask } = useSelector((s) => s.editor);
  const { activeObject, ctxElement } = useSelector((s) => s.control);

  const [swView, setSwView] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isFullView, setIsFullView] = useState(false);
  const [viewOffset, setViewOffset] = useState([0, 0]);
  const [viewWidth, setViewWidth] = useState(["50%", "50%"]);
  const [reqRefresh, setReqRefresh] = useState(0);
  const [pChanges, setChanges] = useState(0);

  const loadStoredProject = () => {
    const pjt = ReadLocalProject();

    if (checkValidProject(pjt)) {
      dispatch(loadProject(pjt));
    } else {
      goHomePage();
    }
  };

  const loadCloudProject = (xid) => {
    ProjectAPI.getProject(
      user,
      "edit",
      xid,
      (m) => {
        var pjt = extractProject(m);
        if (checkValidProject(pjt)) {
          dispatch(loadProject(pjt));
        } else {
          goHomePage();
        }
      },
      (x) => {
        goHomePage();
      }
    );
  };

  const hResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
      return;
    }

    setIsMobile(false);
  };

  useEffect(() => {
    hResize();
    window.addEventListener("resize", hResize);

    var xid = null;

    const urlParam = getUrlParams();
    if (urlParam) {
      if (urlParam.id !== undefined) {
        xid = urlParam.id;
      }
    }

    if (xid) {
      loadCloudProject(xid);
    } else {
      loadStoredProject();
    }

    return () => {
      window.removeEventListener("resize", hResize);
    };
  }, []);

  useEffect(() => {
    if (project.id && project.type) {
      if (activeObject === null) {
        if (Array.isArray(project.objects)) {
          var aOBx = null;
          var aTsk = activeTask;
          for (var i = 0; i < project.objects.length; i++) {
            if (aOBx === null) {
              aOBx = project.objects[i];
              dispatch(setActiveObject(aOBx));
            }
            if (aTsk === null && project.objects[i].isTask) {
              aTsk = project.objects[i].id;
              dispatch(setActiveTask(aTsk));
            }
          }
        }
      }

      if (project.changes > pChanges) {
        const tmp = getProjectThumb(ctxElement, project.scrSize);
        if (tmp) {
          dispatch(setProjectThumb(tmp));
          setChanges(project.changes);
          WriteLocalProject({ ...project, thumb: tmp });
        }
      }
    }
  }, [project]);

  useEffect(() => {
    if (isMobile) {
      if (swView) {
        setViewOffset([0, -650]);
        setViewWidth(["100%", "100%"]);
      } else {
        setViewOffset([-650, 0]);
        setViewWidth(["100%", "100%"]);
      }
    } else if (isFullView) {
      const w = 3000;
      if (swView) {
        setViewOffset([0, -w]);
        setViewWidth(["100%", "100%"]);
      } else {
        setViewOffset([-w, 0]);
        setViewWidth(["100%", "100%"]);
      }
    } else {
      setViewOffset([0, 0]);
      setViewWidth(["50%", "50%"]);
    }

    const tout = setTimeout(() => {
      setReqRefresh(reqRefresh + 1);
    }, 200);
    return () => {
      clearTimeout(tout);
    };
  }, [swView, isMobile, isFullView]);

  return (
    <div className={classes.root}>
      <div className={classes.designView}>
        <div
          className={classes.block}
          style={{ left: viewOffset[0], width: viewWidth[0] }}
        >
          <BlocklyView reqRefresh={reqRefresh} />
          <ThumbImg />
        </div>
        <div
          className={classes.animation}
          style={{ right: viewOffset[1], width: viewWidth[1] }}
        >
          <div className={classes.player}>
            <EditWindow />
          </div>
          <div className={classes.objects}>
            <ObjectsProps />
          </div>
        </div>

        <ImgObjectsModal />
        <ShapesObjectsModal />
        <TextObjectsModal />
      </div>

      <FooterBar
        isMobile={isMobile}
        swView={swView}
        setSwView={setSwView}
        setFull={setIsFullView}
        isFull={isFullView}
      />

      <LoadingPaper open={project.type === null} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    height: "100%",
    backgroundColor: "#eee",
    paddingBottom: "50px",
  },
  designView: {
    position: "relative",
    display: "block",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  block: {
    position: "absolute",
    display: "block",
    height: "100%",
    top: 0,
    transitionProperty: "right, left",
    "-webkit-transition": "0.2s ease 0s",
    transition: "0.2s ease 0s",
  },
  animation: {
    position: "absolute",
    display: "block",
    height: "100%",
    top: 0,
    paddingBottom: "122px",
    transitionProperty: "right, left",
    "-webkit-transition": "0.2s ease 0s",
    transition: "0.2s ease 0s",
  },
  player: {
    width: "100%",
    height: "100%",
  },
  objects: {
    position: "absolute",
    display: "block",
    width: "100%",
    height: "120px",
    bottom: 0,
  },
}));

export default Designer;
