import loadMsgs_ar from "./msg/ar";
import loadMsgs_en from "./msg/en";

const loadTexts = (lang) => {
  switch (lang) {
    case "ar":
      loadMsgs_ar();
      break;

    default:
      loadMsgs_en();
      break;
  }
};

export default loadTexts;
