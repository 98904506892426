import React from "react";
import { HexColorPicker } from "react-colorful";
import { makeStyles } from "@mui/styles";
import { Switch, TextField, ButtonBase } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import CModal from "../tools/CModal";
import { CancelBTN, OkBTN } from "../tools/buttons";

import { useSelector, useDispatch } from "react-redux";
import { updateProjectInfo } from "../data/project";

const SettingsModal = ({ open, close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector((s) => s.project);
  const text = useSelector((s) => s.settings.texts.designer);

  const [bkClr, setBkColor] = React.useState([false, "#fff"]);
  const [isVisible, setVisible] = React.useState(0);
  const [shareStat, setShareStat] = React.useState(0);
  const [appSz, setAppSz] = React.useState([100, 100]);
  const [newName, setNewName] = React.useState("");

  React.useEffect(() => {
    setAppSz(project.scrSize);
    setNewName(project.title);
    setShareStat(project.share);
    setVisible(project.visible);
    setBkColor([false, project.bkColor]);
  }, [open]);

  const hChangeSize = (ev) => {
    var x = parseInt(ev.target.value);
    if (ev.target.name === "cx") {
      if (x > 2000) x = 2000;
      else if (x < 1) x = 1;
      setAppSz([x, appSz[1]]);
    } else if (ev.target.name === "cy") {
      if (x > 1500) x = 1500;
      else if (x < 1) x = 1;
      setAppSz([appSz[0], x]);
    }
  };

  const hSaveAll = () => {
    dispatch(
      updateProjectInfo({
        title: newName,
        visible: isVisible,
        share: shareStat,
        scrSize: appSz,
        bkColor: bkClr[1],
      })
    );
    close();
  };

  React.useEffect(() => {
    setVisible(shareStat > 0 ? 1 : isVisible);
  }, [shareStat]);

  React.useEffect(() => {
    setShareStat(isVisible < 1 ? 0 : shareStat);
  }, [isVisible]);

  return (
    <CModal open={open} close={close}>
      <div className={classes.root}>
        <div className={classes.section}>
          <div className={classes.row}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={text.projectname}
              value={newName}
              autoFocus
              onChange={(event) => {
                setNewName(event.target.value);
              }}
            />
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.head}> {text.appsize}</div>

          <div className={classes.row}>
            <div className={classes.item}>
              <span className={classes.lable}>{text.width}</span>
              <input
                className={classes.control}
                name="cx"
                type="number"
                min={100}
                max={1500}
                value={appSz[0]}
                onChange={hChangeSize}
              />
            </div>
            <div className={classes.item}>
              <span className={classes.lable}>{text.height}</span>
              <input
                className={classes.control}
                name="cy"
                type="number"
                min={100}
                max={1500}
                value={appSz[1]}
                onChange={hChangeSize}
              />
            </div>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.row}>
            <span className={classes.lable}>
              {isVisible > 0 ? text.public : text.private}
            </span>
            <ButtonBase
              className={classes.itemBtn}
              onClick={() => {
                setVisible(isVisible === 0 ? 1 : 0);
              }}
            >
              {isVisible > 0 ? (
                <VisibilityIcon style={{ color: "#2A3", fontSize: 25 }} />
              ) : (
                <VisibilityOffIcon style={{ color: "#444", fontSize: 25 }} />
              )}
            </ButtonBase>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.row}>
            <span className={classes.lable}>{text.share}</span>

            <Switch
              checked={shareStat > 0}
              onChange={(ev) => {
                setShareStat(ev.target.checked ? 1 : 0);
              }}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.row}>
            {bkClr[0] ? (
              <>
                <HexColorPicker
                  color={bkClr[1]}
                  onChange={(c) => {
                    setBkColor([true, c]);
                  }}
                />
                <div className={classes.pickerTx}>
                  <div
                    className={classes.colorBx}
                    style={{ backgroundColor: bkClr[1] }}
                  ></div>
                  <ButtonBase
                    onClick={() => {
                      setBkColor([false, bkClr[1]]);
                    }}
                  >
                    <CheckIcon style={{ color: "#2A3", fontSize: 30 }} />
                  </ButtonBase>
                </div>
              </>
            ) : (
              <>
                <span className={classes.lable}>{text.bkcolor}</span>
                <div
                  className={classes.colorBx}
                  style={{ backgroundColor: bkClr[1] }}
                  onClick={() => {
                    setBkColor([true, bkClr[1]]);
                  }}
                ></div>
              </>
            )}
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.row}>
            <CancelBTN
              onClick={() => {
                close();
              }}
            />

            <OkBTN onClick={hSaveAll} />
          </div>
        </div>
      </div>
    </CModal>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    maxWidth: "500px",
    height: "100%",
    maxHeight: "500px",
    overflowY: "scroll",
    padding: 2,
  },
  section: {
    width: "100%",
    padding: theme.spacing(2, 1),
    border: "1px solid #eee",
    margin: "3px 0px",
  },
  head: {
    width: "100%",
    padding: theme.spacing(1, 0),
    fontSize: 16,
  },
  row: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  item: {
    margin: "0px 10px",
  },
  lable: {
    margin: 5,
    fontSize: 16,
  },
  control: {
    margin: 5,
  },
  colorBx: {
    width: "50px",
    height: "35px",
    border: "1px solid #ccc",
  },
  pickerTx: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-around",
    height: 100,
  },
}));

export default SettingsModal;
