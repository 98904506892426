const toolbox = (lang) => {
  let name = "";
  if (lang == "ar") {
    name = "المهام";
  } else {
    name = "Tasks";
  }

  return (
    `<category categorystyle="tasks_category" name="` +
    name +
    `">
    <block type="task_start"></block>
    <block type="task_on_click"></block>
    <block type="task_on_msg">
      <field name="txt">GO</field> 
    </block>
    <block type="task_on_time">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">500</field>
        </shadow>
      </value>
    </block>
  </category>`
  );
};

export default toolbox;
