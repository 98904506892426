const toolbox = (lang) => {
  let name = "";
  if (lang === "ar") {
    name = "أشكال";
  } else {
    name = "Shapes";
  }

  return (
    `<category categorystyle="shapes_category" name="` +
    name +
    `">
    <block type="shape_color">
      <field name="c">#ff0000</field>
    </block>
    <block type="shape_set_tcolor">
      <value name="xin">
        <shadow type="shape_color">
          <field name="c">#000000</field>
        </shadow>
      </value>
    </block>
    <block type="shape_set_lcolor">
      <value name="xin">
        <shadow type="shape_color">
          <field name="c">#00ff00</field>
        </shadow>
      </value>
    </block>
    <block type="shape_set_fcolor">
      <value name="xin">
        <shadow type="shape_color">
          <field name="c">#ff0000</field>
        </shadow>
      </value>
    </block>
    <block type="shape_set_rgb">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">200</field>
        </shadow>
      </value>
      <value name="yin">
        <shadow type="math_num">
          <field name="num">200</field>
        </shadow>
      </value>
      <value name="zin">
        <shadow type="math_num">
          <field name="num">200</field>
        </shadow>
      </value>
    </block>
  </category>`
  );
};

export default toolbox;
