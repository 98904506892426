import { DIR_IMG_OBJS } from "../data/adresses";

import gLstFile from "./data/gList.json";
import shLstFile from "./data/shapesList.json";

export const loadImageObjects = () => {
  const lst = [];

  if (gLstFile) {
    if (Array.isArray(gLstFile)) {
      gLstFile.forEach((gp) => {
        if (Array.isArray(gp.data)) {
          const x = [];

          gp.data.forEach((ob) => {
            x.push({
              name: gp.gid + ob.oId,
              size: ob.sz,
              fcnt: ob.fcnt,
              thumb: DIR_IMG_OBJS + gp.gid + "/t" + ob.oId + "." + ob.iType,
              img: DIR_IMG_OBJS + gp.gid + "/" + ob.oId + "." + ob.iType,
            });
          });

          lst.push({ name: gp.gid, data: x });
        }
      });
    }
  }

  return lst;
};

export const loadShapesObjects = () => {
  const lst = [];

  if (shLstFile) {
    if (Array.isArray(shLstFile)) {
      shLstFile.map((ob) => {
        lst.push({
          ...ob,
          thumb: DIR_IMG_OBJS + "shapes/" + ob.thumb,
        });
      });
    }
  }

  return lst;
};
