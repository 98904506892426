import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { setProjectTasks } from "../data/project";

const Compiler = ({ code }) => {
  const dispatch = useDispatch();
  const { tasks } = useSelector((s) => s.project);
  const { activeTask } = useSelector((s) => s.editor);

  const Build = () => {
    if (activeTask) {
      var tmpTasks = [];
      if (Array.isArray(tasks)) {
        tasks.forEach((tsk) => {
          if (tsk.id !== activeTask) {
            tmpTasks.push(tsk);
          }
        });
      }

      try {
        const tmp = JSON.parse(code);
        if (Array.isArray(tmp)) {
          tmp.forEach((itm) => {
            if (itm.type === "task") {
              tmpTasks.push({
                id: activeTask,
                name: itm.name,
                stmt: itm.stmt !== undefined ? itm.stmt : null,
              });
            }
          });
        }
      } catch (error) {}

      dispatch(setProjectTasks(tmpTasks));
    }
  };

  React.useEffect(() => {
    if (code) {
      Build();
    }
  }, [code]);

  return <div style={{ display: "none" }}></div>;
};

export default Compiler;
