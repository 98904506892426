import React from "react";
import Blockly from "blockly";
import { makeStyles } from "@mui/styles";
import CodeIcon from "@mui/icons-material/Code";

import { useSelector, useDispatch } from "react-redux";
import { setProjectWorkspaces } from "../data/project";

import { regXCategory } from "./BlocklyCategory";
import BlocklyLoader from "./BlocklyLoader";
import Compiler from "./Compiler";

import "./toolbox_style.css";

const BlocklyView = ({ reqRefresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lang = useSelector((s) => s.settings.lang);
  const { objects, workspaces } = useSelector((s) => s.project);
  const { activeTask } = useSelector((s) => s.editor);

  const [code, setCode] = React.useState("");
  const [wsText, setWsText] = React.useState("");
  const [showTbx, setShowTbx] = React.useState(true);

  const divEl = React.useRef(null);
  const wsRef = React.useRef(null);

  const updateCode = () => {
    if (wsRef.current) {
      setCode(
        '[{"type":"NA"}' +
          Blockly.JavaScript.workspaceToCode(wsRef.current) +
          "]"
      );
    }
  };

  React.useEffect(() => {
    const initBlockly = () => {
      wsRef.current = null;
      var blocksType = null;

      if (activeTask) {
        for (var io = 0; io < objects.length; ++io) {
          if (objects[io].id === activeTask) {
            blocksType = objects[io].type;
            break;
          }
        }
      }

      divEl.current.innerHTML = "";

      regXCategory();
      wsRef.current = BlocklyLoader(divEl.current, lang, blocksType);

      if (wsRef.current && activeTask) {
        if (Array.isArray(workspaces)) {
          var txtData = null;

          for (var iw = 0; iw < workspaces.length; ++iw) {
            const xw = workspaces[iw];
            if (xw.id === activeTask && xw.data.length > 0) {
              txtData = xw.data;
              break;
            }
          }

          if (txtData) {
            const xml = Blockly.Xml.textToDom(txtData);
            Blockly.Xml.domToWorkspace(xml, wsRef.current);
          }
        }

        function bklyEvents(event) {
          const xml = Blockly.Xml.workspaceToDom(wsRef.current);
          setWsText(Blockly.Xml.domToText(xml));
        }

        wsRef.current.addChangeListener(bklyEvents);
      }
    };

    initBlockly();
  }, [lang, reqRefresh, activeTask]);

  React.useEffect(() => {
    if (Array.isArray(workspaces)) {
      const ws = workspaces.map((w) =>
        w.id === activeTask ? { ...w, data: wsText } : w
      );

      dispatch(setProjectWorkspaces(ws));

      updateCode();
    }
  }, [wsText]);

  React.useEffect(() => {
    tglTbx(false);
  }, []);

  const tglTbx = (x) => {
    const tx = document.getElementsByClassName("blocklyToolboxDiv");
    if (tx) {
      if (tx[0]) {
        if (x) {
          tx[0].style.display = "none";
        } else {
          tx[0].style.display = "block";
        }
        setShowTbx(!x);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div
        id="blocklyDiv"
        ref={divEl}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          letterSpacing: "normal",
        }}
      />
      <div
        className={classes.tbx}
        onClick={() => {
          tglTbx(showTbx);
        }}
        style={{
          transform: showTbx ? "scaleX(-1)" : "scaleX(1)",
        }}
      >
        <CodeIcon color="primary" />
      </div>

      <Compiler code={code} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 3,
  },
  tbx: {
    cursor: "pointer",
    display: "block",
    position: "absolute",
    transition: "0.3s",
    top: 5,
    zIndex: 1000,
    padding: "2px 5px",
    margin: "2px 3px",
    borderRadius: 3,
    backgroundColor: "#eee",
  },
}));

export default BlocklyView;
