import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_DIR_M1"] = "Set Direction";

  Blockly.Msg["S_ROTATE_M1"] = "Rotate";
  Blockly.Msg["S_ROTATEXY_M1"] = "Rotate";

  Blockly.Msg["S_SETZOOM_M1"] = "Set zoom to";
  Blockly.Msg["S_ZOOMIN_M1"] = "Zoom In";
  Blockly.Msg["S_ZOOMOUT_M1"] = "Zoom Out";
  Blockly.Msg["S_ZOOMTIMES_M2"] = "times";

  Blockly.Msg["S_SETOPACITY_M1"] = "Set Opacity to";

  Blockly.Msg["S_OPACITY_M1"] = "Change Opacity ";

  Blockly.Msg["S_SETFLIP_M1"] = "Set Flip Status";

  Blockly.Msg["S_FLIP_M1"] = "Flip 180°";
};

export default loadMsgs;
