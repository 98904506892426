import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import {
  getArgument,
  buildStmt,
  buildGroup,
  inputToParams,
  getStmtCount,
} from "../helpers";

const MAX_CNT = 999999;

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["loop_forever"] = function (block) {
    if (block.getParent() !== null) {
      var stmt = Blockly.JavaScript.statementToCode(block, "stmt");

      const n = getStmtCount(stmt);
      if (n > 1) {
        const code = buildGroup("loop", [0, MAX_CNT, 0], stmt);
        return code;
      }
    }
    return "";
  };

  Blockly.JavaScript["loop_count"] = function (block) {
    if (block.getParent() !== null) {
      var stmt = Blockly.JavaScript.statementToCode(block, "stmt");

      const n = getStmtCount(stmt);
      if (n > 1) {
        const cnt = block.getFieldValue("cnt");
        const code = buildGroup("loop", [0, cnt, 0], stmt);

        return code;
      }
    }
    return "";
  };

  Blockly.JavaScript["loop_while_do"] = function (block) {
    if (block.getParent() !== null) {
      const stmt = Blockly.JavaScript.statementToCode(block, "stmt");

      const n = getStmtCount(stmt);
      if (n > 1) {
        const xin = getArgument("xin", block);
        if (xin !== null) {
          var ss = "";
          if (xin[1].type === "data") {
            if (xin[1].value > 0) {
              ss = stmt;
            }
          } else {
            const prm = [];
            ss = inputToParams(xin, prm);
            if (prm.length > 0) {
              ss +=
                buildStmt("logic", "logicIf", [2, 0, ...prm]) +
                buildStmt("break", "break", [0, 0, 0]) +
                stmt;
            }
          }

          if (ss.length > 0) {
            const code = buildGroup("loop", [0, MAX_CNT, 0], ss);
            return code;
          }
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["loop_do_while"] = function (block) {
    if (block.getParent() !== null) {
      const stmt = Blockly.JavaScript.statementToCode(block, "stmt");
      const n = getStmtCount(stmt);
      if (n > 1) {
        const xin = getArgument("xin", block);
        if (xin !== null) {
          var ss = "";
          if (xin[1].type === "data") {
            if (xin[1].value <= 0) {
              ss = buildStmt("break", "break", [0, 0, 0]);
            }
          } else {
            const prm = [];
            ss = inputToParams(xin, prm);
            if (prm.length > 0) {
              ss +=
                buildStmt("logic", "logicIf", [2, 0, ...prm]) +
                buildStmt("break", "break", [0, 0, 0]);
            }
          }

          const code = buildGroup("loop", [0, MAX_CNT, 0], stmt + ss);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["loop_continue"] = function (block) {
    if (block.getParent() !== null) {
      var code = buildStmt("continue", "continue", [0, 0, 0]);
      return code;
    }
    return "";
  };

  Blockly.JavaScript["loop_break"] = function (block) {
    if (block.getParent() !== null) {
      var code = buildStmt("break", "break", [0, 0, 0]);
      return code;
    }
    return "";
  };

  return toolbox(lang);
};

export default loadBlocks;
