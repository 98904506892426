import Blockly from "blockly";

import * as bl_en from "blockly/msg/en";
import * as bl_ar from "blockly/msg/ar";

import { OBTYPE_IMAGE, OBTYPE_SHAPE, OBTYPE_TEXT } from "../project/defines";
import loadImageObjectBlocks from "./blocks/ImageObjectBlocks";
import loadShapesObjectBlocks from "./blocks/ShapeObjectBuilder";
import { setTheme } from "./BlocklyTheme";

import defaultBlocks from "./blocks/defaultBlocks";

const cpyMemory = { txt: "", id: null };

const regMenu = (id, title, fx) => {
  const mItm = {
    displayText: title,
    preconditionFn: function (scope) {
      return "enabled";
    },
    callback: function (scope) {
      if (fx) fx(scope);
    },
    scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
    id: id,
    weight: 100,
  };
  if (Blockly.ContextMenuRegistry.registry.getItem(id) === null)
    Blockly.ContextMenuRegistry.registry.register(mItm);
};

const BlocklyLoader = (el, lang, type) => {
  var workspace = null;
  var toolbox = "";
  if (lang.name == "ar") {
    Blockly.setLocale(bl_ar);
  } else {
    Blockly.setLocale(bl_en);
  }

  if (el) {
    switch (type) {
      case OBTYPE_IMAGE:
        toolbox = defaultBlocks(lang.name);
        toolbox += loadImageObjectBlocks(lang.name);
        break;
      case OBTYPE_SHAPE:
        toolbox = defaultBlocks(lang.name);
        toolbox += loadShapesObjectBlocks(lang.name);
        break;
      case OBTYPE_TEXT:
        toolbox = defaultBlocks(lang.name);
        toolbox += loadShapesObjectBlocks(lang.name);
        break;

      default:
        break;
    }

    setTheme();

    const options = {
      toolbox:
        `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">` +
        toolbox +
        `</xml>`,
      collapse: false,
      disable: true,
      maxBlocks: 500,
      trashcan: true,
      rtl: lang.dir === "rtl" ? true : false,
      scrollbars: true,
      sounds: true,
      theme: Blockly.Themes.Schoode,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 0.75,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.1,
        pinch: true,
      },
    };

    regMenu("pst_ws", "Past", (s) => {
      if (cpyMemory.id) {
        s.workspace.clear();
        const domText = Blockly.Xml.textToDom(cpyMemory.txt);
        Blockly.Xml.domToWorkspace(domText, s.workspace);
      }
    });

    regMenu("cpy_ws", "Copy", (s) => {
      const xml = Blockly.Xml.workspaceToDom(s.workspace);
      cpyMemory.txt = Blockly.Xml.domToText(xml);
      cpyMemory.id = 10;
    });

    workspace = Blockly.inject(el, options);
    if (workspace) {
      workspace.addChangeListener(Blockly.Events.disableOrphans);
    }
  }

  return workspace;
};

export default BlocklyLoader;
