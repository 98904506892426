import * as React from "react";
import { Provider } from "react-redux";
import { designStore } from "../coms/data/store";

import { makeStyles } from "@mui/styles";

import MainFrame from "../coms/global/MainFrame";
import Header from "../coms/global/header";
import Designer from "../coms/editor/Designer";

import AlertBar from "../coms/global/AlertBar";

const EditorPage = () => {
  const classes = useStyles();

  return (
    <Provider store={designStore}>
      <MainFrame>
        <div className={classes.root}>
          <div className={classes.header}>
            <Header />
          </div>

          <div className={classes.content}>
            <Designer />
          </div>
          <AlertBar />
        </div>
      </MainFrame>
    </Provider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100vh",
    minHeight: "400px",
    paddingTop: "50px",
    backgroundColor: "#fff",
  },
  header: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  content: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
  },
}));

export default EditorPage;
