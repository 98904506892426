import React from "react";

import { makeStyles } from "@mui/styles";
import { IconButton, Menu, MenuItem } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CategoryIcon from "@mui/icons-material/Category";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { green, purple, orange } from "@mui/material/colors";

import { useDispatch } from "react-redux";
import { setObModalStatus } from "../data/editor";

const EditControls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const hInsert = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const hClkMenu = (id) => {
    setAnchorEl(null);
    if (id > 0) {
      dispatch(setObModalStatus(id));
    }
  };

  return (
    <div className={classes.root}>
      <IconButton size="small" className={classes.btn} onClick={hInsert}>
        <AddCircleIcon style={{ fontSize: 25, color: "#FFF" }} />
      </IconButton>

      <Menu
        aria-labelledby=""
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            hClkMenu(1);
          }}
        >
          <InsertPhotoIcon style={{ fontSize: 30, color: green[800] }} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            hClkMenu(2);
          }}
        >
          <CategoryIcon style={{ fontSize: 30, color: purple[600] }} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            hClkMenu(3);
          }}
        >
          <TextFieldsIcon style={{ fontSize: 30, color: orange[700] }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  btn: {
    margin: "0px 2px",
  },
}));

export default EditControls;
