const toolbox = (lang) => {
  let name = "";
  if (lang === "ar") {
    name = "حركات";
  } else {
    name = "Move";
  }

  return (
    `<category categorystyle="move_category" name="` +
    name +
    `">
    <block type="ob_data">
      <field name="op">x</field>
    </block>
    <block type="ob_next_fram"> 
    </block>
    <block type="ob_show_fram">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
    </block>
    <block type="ob_move_forward">
    <field name="op">m</field>
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
    </block>
    <block type="ob_move_angle">
    <field name="op">m</field>
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
      <value name="yin">
        <shadow type="math_ang">
          <field name="ang">15</field>
        </shadow>
      </value>
    </block>
    <block type="ob_set_xy">
    <field name="op">x</field>
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
    </block>
    <block type="ob_goto">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">100</field>
        </shadow>
      </value>
      <value name="yin">
      <shadow type="math_num">
        <field name="num">100</field>
        </shadow>
      </value>
    </block>
  </category>`
  );
};

export default toolbox;
