import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import { inputToParams, buildStmt, getArgument } from "../helpers";

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["ob_direction"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "setDir", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_rotate"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "rotate", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_rotate_xy"] = function (block) {
    if (block.getParent() !== null) {
      const ain = getArgument("ain", block);
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);

      if (xin !== null && yin !== null && ain !== null) {
        const prm = [];
        var code = inputToParams(ain, prm);

        if (prm.length > 0) {
          code += inputToParams(xin, prm);

          if (prm.length > 2) {
            code += inputToParams(yin, prm);

            if (prm.length > 4) {
              code += buildStmt("action", "rotateXY", prm);
              return code;
            }
          }
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_set_zoom"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "setZoom", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_zoomin"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "zoomIn", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_zoomout"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "zoomOut", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_set_opacity"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "setOpacity", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_add_opacity"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "opacity", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_set_flip"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      const f = block.getFieldValue("f") === "y" ? 1 : 0;

      code = buildStmt("action", "setFlip", [f, 0, 0]);
    }

    return code;
  };

  Blockly.JavaScript["ob_tgl_flip"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      code = buildStmt("action", "tglFlip", [0, 0, 0]);
    }

    return code;
  };

  return toolbox(lang);
};

export default loadBlocks;
