import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_TCOLOR_M1"] = "Set text color";
  Blockly.Msg["S_TCOLOR_TT"] = "Set text color";

  Blockly.Msg["S_LCOLOR_M1"] = "Set border color";
  Blockly.Msg["S_LCOLOR_TT"] = "Set border color";

  Blockly.Msg["S_FCOLOR_M1"] = "Set fill color";
  Blockly.Msg["S_FCOLOR_TT"] = "Set fill color";
};

export default loadMsgs;
