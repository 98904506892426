import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";
import sounds from "../../../../data/sounds.json";

import { getArgument, buildStmt, inputToParams } from "../helpers";

const audBlocks = () => {
  const blks = [
    {
      type: "basic_audio_play",
      message0: "%{BKY_S_PLAY_SOUND_M1} %1",
      args0: [
        {
          type: "field_dropdown",
          name: "op",
          options: sounds.map((x) => [x.title, x.name]),
        },
      ],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      style: "basic_blocks",
      tooltip: "",
      helpUrl: "",
    },
  ];

  Blockly.defineBlocksWithJsonArray(blks);
};

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);
  audBlocks();

  Blockly.JavaScript["basic_audio_play"] = function (block) {
    if (block.getParent() !== null) {
      var op = block.getFieldValue("op");
      const code = buildStmt("basic", "playSound", [op, 0, 0]);
      return code;
    }

    return "";
  };

  Blockly.JavaScript["basic_say_msg"] = function (block) {
    if (block.getParent() !== null) {
      const txt = block.getFieldValue("txt");
      const code = buildStmt("basic", "sayMsg", [txt, 0, 0]);
      return code;
    }

    return "";
  };

  Blockly.JavaScript["basic_stop_talk"] = function (block) {
    if (block.getParent() !== null) {
      const code = buildStmt("basic", "stopTalk", [0, 0, 0]);
      return code;
    }

    return "";
  };

  Blockly.JavaScript["basic_wait_msg"] = function (block) {
    if (block.getParent() !== null) {
      const txt = block.getFieldValue("txt");
      if (txt) {
        const code = buildStmt("basic", "waitMsg", [txt, 0, 0]);
        return code;
      }
    }

    return "";
  };

  Blockly.JavaScript["basic_send_msg"] = function (block) {
    if (block.getParent() !== null) {
      const txt = block.getFieldValue("txt");
      if (txt) {
        const code = buildStmt("basic", "sendMsg", [txt, 0, 0]);
        return code;
      }
    }

    return "";
  };

  Blockly.JavaScript["basic_wait_time"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin) {
        var prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("basic", "waitTime", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["basic_wait_click"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      code = buildStmt("basic", "waitClick", [0, 0, 0]);
    }

    return code;
  };

  Blockly.JavaScript["basic_hide_me"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      code = buildStmt("basic", "hideMe", [0, 0, 0]);
    }

    return code;
  };

  Blockly.JavaScript["basic_show_me"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      code = buildStmt("basic", "showMe", [0, 0, 0]);
    }

    return code;
  };

  return toolbox(lang);
};

export default loadBlocks;
