import React from "react";

import { makeStyles } from "@mui/styles";

import { OBTYPE_IMAGE, OBTYPE_SHAPE, OBTYPE_TEXT } from "../project/defines";
import { Column } from "./props/PropeLayout";

import ColorProps from "./props/ColorProps";
import GonProps from "./props/GonProps";
import ArcProps from "./props/ArcProps";
import OvalProps from "./props/OvalProps";
import RectProps from "./props/RectProps";
import TriangleProps from "./props/TriangleProps";
import TrapeziumProps from "./props/TrapeziumProps";
import TextProps from "./props/TextProps";
import ObjectThumb from "./props/ObjectThumb";
import GlobalProps from "./props/GlobalProps";
import ImageProps from "./props/ImageProps";

import { useSelector, useDispatch } from "react-redux";
import { setActiveObject } from "../data/control";
import { setActiveTask } from "../data/editor";
import { setProjectObjects } from "../data/project";
import ArrowProps from "./props/ArrowProps";

const ObjectsProps = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { objects } = useSelector((s) => s.project);
  const { activeTask } = useSelector((s) => s.editor);
  const { run, activeObject } = useSelector((s) => s.control);

  React.useEffect(() => {
    if (activeObject && run !== 1) {
      if (activeObject.isTask) {
        if (activeTask !== activeObject.id) {
          dispatch(setActiveTask(activeObject.id));
        }
      }
    }
  }, [activeObject]);

  const updateObject = (obx) => {
    if (Array.isArray(objects)) {
      if (objects.length > 0) {
        const objs = objects.map((o) => (o.id !== obx.id ? o : obx));
        dispatch(setProjectObjects(objs));
      }
    }
    dispatch(setActiveObject(obx));
  };

  const showPathProps = () => {
    if (activeObject === null) return <></>;
    if (activeObject.type === OBTYPE_IMAGE)
      return <ImageProps update={updateObject} />;

    if (activeObject.type === OBTYPE_TEXT)
      return <TextProps update={updateObject} />;

    if (activeObject.name === "arrow")
      return <ArrowProps update={updateObject} />;

    if (activeObject.name === "arc") return <ArcProps update={updateObject} />;

    if (activeObject.name === "oval")
      return <OvalProps update={updateObject} />;

    if (activeObject.name === "gon") return <GonProps update={updateObject} />;

    if (activeObject.name === "rect")
      return <RectProps update={updateObject} />;

    if (activeObject.name === "triangle")
      return <TriangleProps update={updateObject} />;

    if (activeObject.name === "trapezium")
      return <TrapeziumProps update={updateObject} />;
  };

  const showColorProps = () => {
    if (activeObject === null) return <></>;

    let tc = false;
    let fc = false;
    let lc = false;

    if (activeObject.type === OBTYPE_IMAGE) {
      tc = true;
    }
    if (activeObject.type === OBTYPE_TEXT) {
      tc = true;
      fc = true;
      lc = true;
    }
    if (activeObject.type === OBTYPE_SHAPE) {
      fc = true;
      lc = true;
    }

    return (
      <ColorProps update={updateObject} isTxt={tc} isFill={fc} isBorder={lc} />
    );
  };

  return (
    <div className={classes.root} dir="ltr">
      <div className={classes.container}>
        <Column>
          <ObjectThumb />
          <GlobalProps update={updateObject} />

          {showColorProps()}
          {showPathProps()}
        </Column>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "2px",
    margin: 0,
  },
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    borderRadius: "5px",
    width: "100%",
    height: "100%",
    padding: "3px",
    overflowX: "scroll",
    overflowY: "hidden",

    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      borderRadius: "3px",
    },
  },
}));

export default ObjectsProps;
