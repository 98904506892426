const toolbox = (lang) => {
  let name = "";
  if (lang === "ar") {
    name = "أساسي";
  } else {
    name = "Basic";
  }

  return (
    `<category categorystyle="basic_category" name="` +
    name +
    `"> 
    <block type="basic_wait_time">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">100</field>
        </shadow>
      </value>
    </block>
    <block type="basic_wait_click"></block> 
    <block type="basic_wait_msg">
      <field name="txt">GO</field> 
    </block>
    <block type="basic_send_msg">
      <field name="txt">GO</field> 
    </block>
    <block type="basic_audio_play">
      <field name="f">cartoon1</field>
    </block>
    <block type="basic_say_msg">
      <field name="txt">Hi</field> 
    </block>
    <block type="basic_stop_talk"></block>
    <block type="basic_hide_me"></block>
    <block type="basic_show_me"></block>
  </category>`
  );
};

export default toolbox;
