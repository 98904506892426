import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import {
  buildStmt,
  createVarName,
  getArgument,
  varToStmt,
  superToStmt,
  dataToStmt,
  inputToParams,
  paramToStmt,
} from "../helpers";

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["math_set_super"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          const vn = block.getFieldValue("vn");
          code += buildStmt("calc", "setSuper", [vn, ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["math_set_var"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          const vn = block.getFieldValue("vn");
          code += buildStmt("calc", "setVar", [vn, ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["math_super"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      var vn = block.getFieldValue("vn");
      if (vn.length !== undefined) {
        if (vn.length > 0) {
          code = superToStmt(vn);
        }
      }
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_var"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      var vn = block.getFieldValue("vn");
      if (vn.length !== undefined) {
        if (vn.length > 0) {
          code = varToStmt(vn);
        }
      }
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_inc_var"] = function (block) {
    if (block.getParent() !== null) {
      var vn = block.getFieldValue("vn");
      if (vn.length !== undefined) {
        if (vn.length > 0) {
          var op = block.getFieldValue("op") === "inc" ? 1 : -1;
          const code = buildStmt("calc", "incVar", [vn, op, 0]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["math_inc_super"] = function (block) {
    if (block.getParent() !== null) {
      var vn = block.getFieldValue("vn");
      if (vn.length !== undefined) {
        if (vn.length > 0) {
          var op = block.getFieldValue("op") === "inc" ? 1 : -1;
          const code = buildStmt("calc", "incSuper", [vn, op, 0]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["math_num"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      var num = parseFloat(block.getFieldValue("num"));

      code = dataToStmt(num);
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_time"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      code = paramToStmt("tm");
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_ang"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      var ang = Math.round(10 * parseFloat(block.getFieldValue("ang"))) / 10;

      code = dataToStmt(ang);
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_const"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      const op = block.getFieldValue("op");
      var res = 0;
      if (op === "pi") {
        res = Math.PI;
      } else if (op === "e") {
        res = Math.E;
      } else if (op === "sqrt2") {
        res = Math.SQRT2;
      } else if (op === "sqrt05") {
        res = Math.SQRT1_2;
      }

      code = dataToStmt(res);
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_fx"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        const vName = createVarName();
        var code = varToStmt(vName);
        code += inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          const op = block.getFieldValue("op");
          code += buildStmt("calc", "mathFX", [vName, op, ...prm]);
          return [code, Blockly.JavaScript.ORDER_NONE];
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_trigo"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        const vName = createVarName();
        var code = varToStmt(vName);
        code += inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          const op = block.getFieldValue("op");
          code += buildStmt("calc", "mathTrigo", [vName, op, ...prm]);
          return [code, Blockly.JavaScript.ORDER_NONE];
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_calc"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);
      if (xin !== null && yin !== null) {
        const prm = [];
        const vName = createVarName();
        var code = varToStmt(vName);
        code += inputToParams(xin, prm);

        if (prm.length > 0) {
          code += inputToParams(yin, prm);

          if (prm.length > 2) {
            var op = block.getFieldValue("op");
            code += buildStmt("calc", "mathCalc", [vName, op, ...prm]);
            return [code, Blockly.JavaScript.ORDER_NONE];
          }
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["math_cmp"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);
      if (xin !== null && yin !== null) {
        const prm = [];
        const vName = createVarName();
        var code = varToStmt(vName);
        code += inputToParams(xin, prm);

        if (prm.length > 0) {
          code += inputToParams(yin, prm);

          if (prm.length > 2) {
            var op = block.getFieldValue("op");
            code += buildStmt("calc", "mathCmp", [vName, op, ...prm]);
            return [code, Blockly.JavaScript.ORDER_NONE];
          }
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  return toolbox(lang);
};

export default loadBlocks;
