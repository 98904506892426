import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_IF_M1"] = "IF";

  Blockly.Msg["S_ELSE_M1"] = "Else";

  Blockly.Msg["S_LOGIC_NOT_M1"] = "NOT";

  Blockly.Msg["S_LOGICVAL_OP1"] = "TRUE";
  Blockly.Msg["S_LOGICVAL_OP2"] = "FALSE";
};

export default loadMsgs;
