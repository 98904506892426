import React from "react";

import { makeStyles } from "@mui/styles";
import BlockIcon from "@mui/icons-material/Block";

import { Row } from "./PropeLayout";
import ImgAngel from "./ImgAngel";

import { useSelector } from "react-redux";

const ArcProps = ({ update }) => {
  const classes = useStyles();
  const { activeObject } = useSelector((s) => s.control);

  const hChangeLength = (ev) => {
    var prm = null;
    if (ev.target.name === "r") {
      prm = {
        ...activeObject.params,
        r: parseInt(ev.target.value < 1000 ? ev.target.value : 1000),
      };
    }
    if (ev.target.name === "alfa") {
      prm = {
        ...activeObject.params,
        alfa: parseInt(ev.target.value < 360 ? ev.target.value : 0),
      };
    }

    if (prm) {
      const obx = { ...activeObject, params: prm };
      update(obx);
    }
  };

  if (activeObject.params) {
    return (
      <>
        <Row w={80}>
          <BlockIcon
            style={{ transform: "rotate(45deg)", fontSize: 22, color: "#c80" }}
          />
          <input
            className={classes.control}
            name="r"
            type="number"
            min={1}
            max={1000}
            value={activeObject.params.r}
            onChange={hChangeLength}
          />
        </Row>
        <Row w={80}>
          <ImgAngel />
          <input
            className={classes.control}
            name="alfa"
            type="number"
            min={0}
            max={360}
            value={activeObject.params.alfa}
            onChange={hChangeLength}
          />
        </Row>
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default ArcProps;
