import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import {
  buildStmt,
  getStmtCount,
  getArgument,
  createVarName,
  dataToStmt,
  varToStmt,
  inputToParams,
} from "../helpers";

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["logic_val"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      var val = block.getFieldValue("val");
      code = dataToStmt(val);
    }
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["logic_if"] = function (block) {
    if (block.getParent() !== null) {
      const stmt = Blockly.JavaScript.statementToCode(block, "stmt");

      var nn = getStmtCount(stmt);
      if (nn > 1) {
        const xin = getArgument("xin", block);
        if (xin !== null) {
          const prm = [];
          var code = inputToParams(xin, prm);

          if (prm.length > 0) {
            code += buildStmt("logic", "logicIf", [nn, 1, ...prm]);
            code += stmt;
            return code;
          }
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["logic_if_else"] = function (block) {
    if (block.getParent() !== null) {
      const stmt1 = Blockly.JavaScript.statementToCode(block, "stmt1");
      const stmt2 = Blockly.JavaScript.statementToCode(block, "stmt2");

      var n1 = getStmtCount(stmt1);
      var n2 = getStmtCount(stmt2);
      if (n1 > 1 || n2 > 1) {
        const xin = getArgument("xin", block);
        if (xin !== null) {
          const prm = [];
          var code = inputToParams(xin, prm);

          if (prm.length > 0) {
            code += buildStmt("logic", "logicIf", [n1 + 1, 1, ...prm]);
            code += stmt1;
            if (n2 > 1) {
              code += buildStmt("basic", "jumpTo", [n2, 0, 0]);
              code += stmt2;
            }
            return code;
          }
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["logic_not"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        const vName = createVarName();
        var code = varToStmt(vName);

        code += inputToParams(xin, prm);

        if (prm.length > 0) {
          code += buildStmt("logic", "logicNot", [vName, ...prm]);
          return [code, Blockly.JavaScript.ORDER_NONE];
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["logic_operation"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);
      if (xin !== null && yin !== null) {
        const prm = [];
        const vName = createVarName();
        var code = varToStmt(vName);

        code += inputToParams(xin, prm);

        if (prm.length > 0) {
          code += inputToParams(yin, prm);

          if (prm.length > 2) {
            var op = block.getFieldValue("op");
            code += buildStmt("logic", "logicOperation", [vName, op, ...prm]);
            return [code, Blockly.JavaScript.ORDER_NONE];
          }
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  return toolbox(lang);
};

export default loadBlocks;
