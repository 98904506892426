import React from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Menu, MenuItem, CircularProgress } from "@mui/material";
import { orange } from "@mui/material/colors";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveIcon from "@mui/icons-material/Save";
import ExtensionIcon from "@mui/icons-material/Extension";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import ViewCompactOutlinedIcon from "@mui/icons-material/ViewCompactOutlined";

import SettingsModal from "./SettingsModal";
import { gotoPage } from "../tools/browserHelpers";

import ProjectAPI from "../api/projectApi";
import { useSelector, useDispatch } from "react-redux";
import { updateProjectInfo } from "../data/project";
import { getProjectThumb } from "../project/ProjectOperations";

const FooterBar = ({ isMobile, swView, setSwView, setFull, isFull }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const texts = useSelector((s) => s.settings.texts.site);
  const user = useSelector((s) => s.settings.user);
  const project = useSelector((s) => s.project);
  const { ctxElement } = useSelector((s) => s.control);

  const [menuAncr, setMenuAncr] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(0);

  const hToggleVisible = () => {
    dispatch(
      updateProjectInfo({
        visible: project.visible > 0 ? 0 : 1,
        share: project.visible < 1 ? 0 : project.share,
      })
    );
  };

  const hSignin = () => {
    setMenuAncr(null);
    gotoPage("/signin");
  };

  const hSave = (ev) => {
    if (saving > 0) {
      return;
    }

    if (user) {
      if (project.id) {
        const thumb = getProjectThumb(ctxElement, project.scrSize);
        const data = JSON.stringify({
          bkColor: project.bkColor,
          scrSize: project.scrSize,
          objects: project.objects,
          tasks: project.tasks,
        });
        const code = JSON.stringify({
          workspaces: project.workspaces,
        });

        setSaving(1);

        ProjectAPI.saveProject(
          user,
          {
            id: project.id,
            type: project.type,
            title: project.title,
            visible: project.visible,
            share: project.share,
            img: thumb,
            data: data,
            code: code,
          },
          (m) => {
            setSaving(2);
            setTimeout(() => {
              setSaving(0);
            }, 3000);
          },
          (x) => {
            setSaving(3);
            setTimeout(() => {
              setSaving(0);
            }, 3000);
          }
        );
      }
    } else {
      setMenuAncr(ev.currentTarget);
    }
  };

  const getSavingIcon = () => {
    if (saving === 1) {
      return <CircularProgress size={20} style={{ color: "#eee" }} />;
    }

    if (saving === 2) {
      return <DoneIcon style={{ color: "#0E0", fontSize: 22 }} />;
    }

    if (saving === 3) {
      return <ErrorOutlineIcon style={{ color: "#E11", fontSize: 22 }} />;
    }

    return <SaveIcon style={{ color: "#FFF", fontSize: 28 }} />;
  };

  const swControls = () => {
    if (isMobile || isFull) {
      return (
        <div
          className={classes.switch}
          onClick={() => {
            setSwView(!swView);
          }}
        >
          <ExtensionIcon
            style={{ color: swView ? "#4A4" : "#aaa", fontSize: 28 }}
          />
          <OndemandVideoIcon
            style={{ color: swView ? "#aaa" : "#44A", fontSize: 28 }}
          />
        </div>
      );
    }

    return <></>;
  };

  const fullControls = () => {
    if (isMobile) return <></>;

    return (
      <IconButton
        className={classes.itemBtn}
        size="small"
        onClick={() => {
          setFull(!isFull);
        }}
      >
        {isFull ? (
          <ViewCompactOutlinedIcon style={{ color: "#fff", fontSize: 28 }} />
        ) : (
          <FitScreenIcon style={{ color: "#fff", fontSize: 28 }} />
        )}
      </IconButton>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.raw}>
        <IconButton
          className={classes.itemBtn}
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          <SettingsIcon style={{ color: "#FFF", fontSize: 28 }} />
        </IconButton>

        {fullControls()}
        {swControls()}
      </div>

      <div className={classes.title}>{project.title}</div>

      <div className={classes.raw}>
        <IconButton
          className={classes.itemBtn}
          size="small"
          onClick={() => {
            hToggleVisible();
          }}
        >
          {project.visible > 0 ? (
            <VisibilityIcon style={{ color: "#fff", fontSize: 28 }} />
          ) : (
            <VisibilityOffIcon style={{ color: "#fff", fontSize: 28 }} />
          )}
        </IconButton>

        <IconButton
          className={classes.itemBtn}
          size="small"
          onClick={(ev) => {
            hSave(ev);
          }}
        >
          {getSavingIcon()}
        </IconButton>
      </div>

      <Menu
        anchorEl={menuAncr}
        open={menuAncr !== null}
        onClose={() => {
          setMenuAncr(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={hSignin}>{texts.signin}</MenuItem>
      </Menu>

      <SettingsModal
        open={open}
        close={() => {
          setOpen(false);
        }}
      ></SettingsModal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "48px",

    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: orange[800],
    padding: "0px 8px",
  },
  raw: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
  },
  itemBtn: {
    color: "#fff",
    margin: "0px 10px",
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#fff",
    fontSize: 16,
    margin: theme.spacing(0, 1),
  },
  control: {
    position: "relative",
    width: "100%",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    padding: 1,
    margin: theme.spacing(0, 1),
    backgroundColor: "rgb(250,250,250,.9)",
    border: "1px solid #eee",
    borderRadius: 5,
  },
}));

export default FooterBar;
