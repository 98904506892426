import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_MOVE_DAT_OP1"] = "الزاوية";
  Blockly.Msg["S_MOVE_DAT_OP2"] = "التكبير";
  Blockly.Msg["S_MOVE_DAT_OP3"] = "الشفافية";

  Blockly.Msg["S_MOVE_STP_M1"] = "تحرك للأمام";
  Blockly.Msg["S_MOVE_STP_M2"] = "خطوات";

  Blockly.Msg["S_SHOW_FRAME_M1"] = "أظهر الحركة";
  Blockly.Msg["S_NEXT_FRAME_M1"] = "الحركة التالية";

  Blockly.Msg["S_MOVE_TIME_M1"] = "تحرك ";
  Blockly.Msg["S_MOVE_TIME_M2"] = "خطوات بزاوية";

  Blockly.Msg["S_MOVE_OP1"] = "تحرك";
  Blockly.Msg["S_MOVE_OP2"] = "إمشي";

  Blockly.Msg["S_SET_XY_M1"] = "الموقع";

  Blockly.Msg["S_GOTO_M1"] = "إذهب إلى";
};

export default loadMsgs;
