import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_VAR_M1"] = "Var";
  Blockly.Msg["S_SUPER_M1"] = "Global";
  Blockly.Msg["S_TIME_M1"] = "Time";

  Blockly.Msg["S_CONST_OP1"] = "sqrt(2)";
  Blockly.Msg["S_CONST_OP2"] = "sqrt(1/2)";

  Blockly.Msg["S_CMP_OP1"] = "<";
  Blockly.Msg["S_CMP_OP2"] = "<=";
  Blockly.Msg["S_CMP_OP3"] = ">";
  Blockly.Msg["S_CMP_OP4"] = ">=";
  Blockly.Msg["S_CMP_OP5"] = "==";
  Blockly.Msg["S_CMP_OP6"] = "!=";

  Blockly.Msg["S_CALCS_OP1"] = "sqrt";
  Blockly.Msg["S_CALCS_OP2"] = "abs";
  Blockly.Msg["S_CALCS_OP3"] = "ln";
  Blockly.Msg["S_CALCS_OP4"] = "log";
};

export default loadMsgs;
