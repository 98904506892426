import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import { buildStmt, getArgument, inputToParams, paramToStmt } from "../helpers";

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["ob_data"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      const op = block.getFieldValue("op");

      code = paramToStmt(op);
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["ob_next_fram"] = function (block) {
    if (block.getParent() !== null) {
      const code = buildStmt("action", "nextFrame", [0, 0, 0]);
      return code;
    }

    return "";
  };

  Blockly.JavaScript["ob_show_fram"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "showFrame", prm);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_move_forward"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          const op = block.getFieldValue("op");
          code += buildStmt("action", "moveForward", [op, ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_move_angle"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);
      if (xin !== null && yin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          code += inputToParams(yin, prm);

          if (prm.length > 2) {
            const op = block.getFieldValue("op");
            code += buildStmt("action", "moveByAngle", [op, ...prm]);
            return code;
          }
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_set_xy"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          const op = block.getFieldValue("op");
          code += buildStmt("action", "setXYPos", [op, ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["ob_goto"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);
      if (xin !== null && yin !== null) {
        const pms = [];
        var code = inputToParams(xin, pms);

        if (pms.length > 0) {
          code += inputToParams(yin, pms);

          if (pms.length > 2) {
            code += buildStmt("action", "gotoXY", pms);
            return code;
          }
        }
      }
    }

    return "";
  };

  return toolbox(lang);
};

export default loadBlocks;
