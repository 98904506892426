import React from "react";
import { makeStyles } from "@mui/styles";
import BlockIcon from "@mui/icons-material/Block";

import { Row } from "./PropeLayout";

import { buildNGon } from "../../project/ShapesBuilder";

import { useSelector } from "react-redux";

const GonProps = ({ update }) => {
  const classes = useStyles();
  const { activeObject } = useSelector((s) => s.control);

  const hChangeLength = (ev) => {
    if (ev.target.name === "r") {
      const prm = {
        ...activeObject.params,
        r: parseInt(ev.target.value < 1000 ? ev.target.value : 1000),
      };
      const tmp = buildNGon(prm);
      if (tmp) {
        const obx = { ...activeObject, params: prm, path: tmp };
        update(obx);
      }
    } else if (ev.target.name === "op") {
      const props = {
        ...activeObject.props,
        op: parseInt(ev.target.value < 10 ? ev.target.value : 10),
      };
      const obx = { ...activeObject, props: props };
      update(obx);
    }
  };

  if (activeObject.params) {
    return (
      <>
        <Row w={80}>
          <BlockIcon
            style={{ transform: "rotate(45deg)", fontSize: 22, color: "#c80" }}
          />
          <input
            className={classes.control}
            name="r"
            type="number"
            min={1}
            max={1000}
            value={activeObject.params.r}
            onChange={hChangeLength}
          />
        </Row>
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default GonProps;
