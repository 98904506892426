import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_LOOP_FOREVER_M1"] = "Loop";

  Blockly.Msg["S_LOOP_WHILE_M1"] = "While";
  Blockly.Msg["S_LOOP_DO_M1"] = "Do";

  Blockly.Msg["S_LOOP_COUNT_M1"] = "Repeat";
  Blockly.Msg["S_LOOP_COUNT_M2"] = "times";

  Blockly.Msg["S_LOOP_CONTINUE_M1"] = "Continue";

  Blockly.Msg["S_LOOP_BREAK_M1"] = "Break";
};

export default loadMsgs;
