import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_ON_START_M1"] = "On Start";

  Blockly.Msg["S_ON_CLICK_M1"] = "On Click";

  Blockly.Msg["S_ON_MSG_M1"] = "On Msg";

  Blockly.Msg["S_ON_TIME_M1"] = "Every";
  Blockly.Msg["S_ON_TIME_M2"] = "Sec";
};

export default loadMsgs;
