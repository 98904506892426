import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_WAIT_TIME_M1"] = "Wait";
  Blockly.Msg["S_WAIT_TIME_M2"] = "mSec";

  Blockly.Msg["S_WAIT_CLICK_M1"] = "Wait Click";

  Blockly.Msg["S_WAIT_MSG_M1"] = "Wait For Msg";
  Blockly.Msg["S_SEND_MSG_M1"] = "Send Msg";

  Blockly.Msg["S_PLAY_SOUND_M1"] = "Play Sound";

  Blockly.Msg["S_SAY_M1"] = "Say";
  Blockly.Msg["S_STOPTALK_M1"] = "Stop Talk";

  Blockly.Msg["S_HIDE_ME_M1"] = "Hide Me";
  Blockly.Msg["S_SHOW_ME_M1"] = "Show Me";
};

export default loadMsgs;
