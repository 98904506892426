import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_DIR_M1"] = "الإتجاه بزاوية";

  Blockly.Msg["S_ROTATE_M1"] = "الإلتفاف بزاوية";
  Blockly.Msg["S_ROTATEXY_M1"] = "الدوران بزاوية";

  Blockly.Msg["S_SETZOOM_M1"] = "تحديد التكبير";
  Blockly.Msg["S_ZOOMIN_M1"] = "تكبير";
  Blockly.Msg["S_ZOOMOUT_M1"] = "تصغير";
  Blockly.Msg["S_ZOOMTIMES_M2"] = "مرات";

  Blockly.Msg["S_SETOPACITY_M1"] = "تحديد الشفافية";

  Blockly.Msg["S_OPACITY_M1"] = "زيادة الشفافية";

  Blockly.Msg["S_SETFLIP_M1"] = "تحديد الاستدارة";

  Blockly.Msg["S_FLIP_M1"] = "استدارة 180°";
};

export default loadMsgs;
