import Blockly from "blockly";

export const createVarName = () =>
  "#V" + Math.round(10000 + Math.random() * 10000);

export const txtToStmt = (v) => ',{"type":"data","value":"' + v + '"}';
export const dataToStmt = (v) => ',{"type":"data","value":' + v + "}";
export const paramToStmt = (n) => ',{"type":"param","name":"' + n + '"}';
export const superToStmt = (n) => ',{"type":"super","name":"' + n + '"}';
export const varToStmt = (n) => ',{"type":"var","name":"' + n + '"}';

export const xinToStmts = (x) => {
  var ss = "";
  if (x !== null) {
    if (x.length !== undefined) {
      for (var i = 2; i < x.length; i++) {
        ss += "," + JSON.stringify(x[i]);
      }
    }
  }
  return ss;
};

export const inputToParams = (xin, pms) => {
  if (xin[1].type === "data") {
    pms.push("d");
    pms.push(xin[1].value);
  } else if (xin[1].type === "param") {
    pms.push("p");
    pms.push(xin[1].name);
  } else if (xin[1].type === "super") {
    pms.push("s");
    pms.push(xin[1].name);
  } else if (xin[1].type === "var") {
    pms.push("v");
    pms.push(xin[1].name);
    return xinToStmts(xin);
  }
  return "";
};

export const parseStmts = (stmt) => {
  try {
    if (stmt.length > 5) {
      const tmp = JSON.parse('[{"type":"NA"}' + stmt + "]");
      return tmp;
    }
  } catch (error) {}
  return null;
};

export const getStmtCount = (stmt) => {
  const tmp = parseStmts(stmt);
  if (tmp !== null) {
    return tmp.length;
  }
  return 0;
};

export const getArgument = (name, block) => {
  try {
    const xIn = Blockly.JavaScript.valueToCode(
      block,
      name,
      Blockly.JavaScript.ORDER_NONE
    ).trim();

    return parseStmts(xIn);
  } catch (error) {}

  return null;
};

export const buildStmt = (cat, name, param) => {
  return (
    "," +
    JSON.stringify({
      type: "stmt",
      cat: cat,
      name: name,
      param: param,
    })
  );
};

export const buildGroup = (name, param, stmt) => {
  var ss = ',{"type":"group","name":"' + name + '","stp":0,"param":';

  if (Array.isArray(param)) {
    ss += JSON.stringify(param);
  } else {
    ss += "[]";
  }

  ss += ',"stmt":[{"type":"NA"}' + stmt + "]}";

  return ss;
};

/*

export const buildGroup = (cat, name, param, stmt) => {
  var ss = [{}];
  if (stmt.length > 0) {
    var xx = stmt.trim();
    if (xx[0] === ",") {
      try {
        xx = JSON.parse("[" + xx.slice(1) + "]");
        ss = [{}, ...xx];
      } catch (error) {}
    }
  }

  return (
    "," +
    JSON.stringify({
      type: "group",
      cat: cat,
      name: name,
      stp: 0,
      param: param,
      stmt: ss,
    })
  );
};

*/
