const toolbox = (lang) => {
  let name = "";
  let msg = "";
  if (lang === "ar") {
    name = "عمليات";
    msg = "مرحبا";
  } else {
    name = "Actions";
    msg = "Hi";
  }

  return (
    `<category categorystyle="actions_category" name="` +
    name +
    `">
  <block type="ob_direction">
    <value name="xin">
      <shadow type="math_ang">
        <field name="ang">45</field>
      </shadow>
    </value>
  </block>
  <block type="ob_rotate">
    <value name="xin">
      <shadow type="math_ang">
        <field name="ang">45</field>
      </shadow>
    </value>
  </block>
  <block type="ob_rotate_xy">
    <value name="ain">
      <shadow type="math_ang">
        <field name="ang">45</field>
      </shadow>
    </value>
    <value name="xin">
      <shadow type="math_num">
        <field name="num">50</field>
      </shadow>
    </value>
    <value name="yin">
      <shadow type="math_num">
        <field name="num">50</field>
      </shadow>
    </value>
  </block>
  <block type="ob_set_zoom">
    <value name="xin">
      <shadow type="math_num">
        <field name="num">2</field>
      </shadow>
    </value>
  </block>
  <block type="ob_zoomin">
    <value name="xin">
      <shadow type="math_num">
        <field name="num">2</field>
      </shadow>
    </value>
  </block>
  <block type="ob_zoomout">
    <value name="xin">
      <shadow type="math_num">
        <field name="num">2</field>
      </shadow>
    </value>
  </block>
  <block type="ob_set_opacity">
    <value name="xin">
      <shadow type="math_num">
        <field name="num">50</field>
      </shadow>
    </value>
  </block>
  <block type="ob_add_opacity">
    <value name="xin">
      <shadow type="math_num">
        <field name="num">10</field>
      </shadow>
    </value>
  </block>
  <block type="ob_set_flip">
    <field name="f">t</field>
  </block>
  <block type="ob_tgl_flip"> 
  </block>
  </category>`
  );
};

export default toolbox;
