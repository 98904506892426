import React, { useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setProjectObjects } from "../data/project";
import { setActiveObject } from "../data/control";

const OverLayer = () => {
  const dispatch = useDispatch();
  const { scrSize, objects } = useSelector((s) => s.project);
  const { run, mouse, activeObject } = useSelector((s) => s.control);

  const containerRef = useRef(null);

  const updateObjectPos = () => {
    const ob = objects.map((o) => {
      if (o.id === activeObject.id) {
        const x = mouse.p2[0] - mouse.pOffset[0];
        const y = mouse.p2[1] - mouse.pOffset[1];

        const props = { ...activeObject.props, x: x, y: y };

        const obx = { ...activeObject, props: props };
        dispatch(setActiveObject(obx));
        return obx;
      }
      return o;
    });

    dispatch(setProjectObjects(ob));
  };

  useEffect(() => {
    if (run !== 1) {
      if (mouse.s && mouse.pOffset) {
        if (activeObject) {
          if (activeObject.locked === false) {
            updateObjectPos();
          }
        }
      }
    }
  }, [mouse]);

  return (
    <canvas
      ref={containerRef}
      width={scrSize[0]}
      height={scrSize[1]}
      style={{
        direction: "ltr",
        display: "block",
        position: "absolute",
        pointerEvents: "none",
        top: 0,
        left: 0,
        width: scrSize[0],
        height: scrSize[1],
      }}
    />
  );
};

export default OverLayer;
