import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Modal, Paper, AppBar, Tabs, Tab } from "@mui/material";

import ImagesGallery from "./ImagesGallery";
import UserGallery from "./UserGallery";

import { genID, InitWorkspace, InitImgObject } from "./ObjectsOperations";

import { useSelector, useDispatch } from "react-redux";
import { setObModalStatus } from "../data/editor";
import { setProjectWorkspaces, setProjectObjects } from "../data/project";

const ImgObjectsModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.settings.user);
  const texts = useSelector((s) => s.settings.texts.designer);
  const { obModalStatus } = useSelector((s) => s.editor);
  const { objects, workspaces } = useSelector((s) => s.project);

  const [tabIndex, setTabIndex] = useState(0);

  const hChangeTab = (ev, val) => {
    setTabIndex(val);
  };

  const closeModal = () => {
    dispatch(setObModalStatus(0));
  };

  const addObject = (ob) => {
    const id = genID("IM");

    const params = {
      w: parseInt(ob.size[0]),
      h: parseInt(ob.size[1]),
      fcnt: ob.fcnt,
    };

    const objs = [
      ...objects,
      InitImgObject(id, "image", ob.name, ob.img, ob.thumb, params),
    ];

    const ws = [...workspaces, InitWorkspace(id)];

    dispatch(setProjectObjects(objs));
    dispatch(setProjectWorkspaces(ws));

    closeModal();
  };

  return (
    <Modal
      className={classes.modal}
      open={obModalStatus === 1}
      onClose={closeModal}
    >
      <Paper className={classes.paper}>
        <div className={classes.bx}>
          <AppBar position="static">
            <Tabs
              aria-label=""
              textColor="inherit"
              variant="fullWidth"
              value={tabIndex}
              onChange={hChangeTab}
            >
              <Tab
                style={{ fontSize: 22 }}
                label={texts.library}
                tabIndex={0}
              />
              {user && (
                <Tab
                  style={{ fontSize: 22 }}
                  label={texts.upload}
                  tabIndex={1}
                />
              )}
            </Tabs>
          </AppBar>
          <div className={classes.content}>
            <ImagesGallery show={tabIndex === 0} addObject={addObject} />
            <UserGallery
              show={tabIndex === 1}
              user={user}
              addObject={addObject}
            />
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: "600px",
    maxHeight: "450px",
    padding: "10px 5px",
  },
  bx: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "5px",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999",
      borderRadius: "3px",
    },
  },
  content: {
    display: "block",
    position: "relative",
    width: "100%",
    padding: theme.spacing(1),
  },
}));

export default ImgObjectsModal;
