import tasksCategory from "./category/tasks";
import basicCategory from "./category/basic";
import loopsCategory from "./category/loops";
import logicCategory from "./category/logic";
import mathCategory from "./category/math";

const defaultBlocks = (lng) => {
  let tbx = tasksCategory(lng);
  tbx += loopsCategory(lng);
  tbx += basicCategory(lng);
  tbx += logicCategory(lng);
  tbx += mathCategory(lng);

  return tbx;
};

export default defaultBlocks;
