import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_ON_START_M1"] = "عند البداية";
  Blockly.Msg["S_ON_START_TT"] = "عند البداية";

  Blockly.Msg["S_ON_CLICK_M1"] = "عند النقر";
  Blockly.Msg["S_ON_CLICK_TT"] = "عند النقر";

  Blockly.Msg["S_ON_MSG_M1"] = "عند الرسالة";

  Blockly.Msg["S_ON_TIME_M1"] = "كل ";
  Blockly.Msg["S_ON_TIME_M2"] = "ثانية";
};

export default loadMsgs;
