import React from "react";

import { makeStyles } from "@mui/styles";
import { Slider, ButtonBase } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import OpacityIcon from "@mui/icons-material/Opacity";
import FlipIcon from "@mui/icons-material/Flip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Row } from "./PropeLayout";

import { useSelector, useDispatch } from "react-redux";
import { setProjectObjects } from "../../data/project";

const GlobalProps = ({ update }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeObject } = useSelector((s) => s.control);
  const { objects } = useSelector((s) => s.project);

  const hToggleLock = () => {
    const x = activeObject.locked ? false : true;
    const obx = { ...activeObject, locked: x };
    update(obx);
  };

  const hChangeZoom = (event, value) => {
    var x = parseInt(value);
    if (x > 100) x = 100;
    else if (x < 5) x = 5;
    const prp = { ...activeObject.props, zoom: x };
    const obx = { ...activeObject, props: prp };
    update(obx);
  };

  const hChangeAng = (ev) => {
    if (ev.target.name === "ang") {
      const x = parseInt(ev.target.value);
      const prp = { ...activeObject.props, ang: x };
      const obx = { ...activeObject, props: prp };
      update(obx);
    }
  };

  const hChangeOp = (ev) => {
    if (ev.target.name === "op") {
      const x = parseInt(ev.target.value);
      const prp = { ...activeObject.props, op: x };
      const obx = { ...activeObject, props: prp };
      update(obx);
    }
  };

  const hChangeChk = (ev) => {
    if (ev.target.name === "flip") {
      const prp = { ...activeObject.props, flip: ev.target.checked };
      const obx = { ...activeObject, props: prp };
      update(obx);
    } else if (ev.target.name === "visible") {
      const prp = { ...activeObject.props, visible: ev.target.checked };
      const obx = { ...activeObject, props: prp };
      update(obx);
    }
  };

  const hChangePrps = (ev) => {
    var prp = null;
    if (ev.target.name === "x") {
      prp = { ...activeObject.props, x: parseInt(ev.target.value) };
    } else if (ev.target.name === "y") {
      prp = { ...activeObject.props, y: parseInt(ev.target.value) };
    }

    if (prp) {
      const obx = { ...activeObject, props: prp };
      update(obx);
    }
  };

  const hMoveObject = (up) => {
    if (activeObject) {
      if (Array.isArray(objects)) {
        if (objects.length > 0) {
          var pos = 0;
          var obsA = [];
          var xob = null;
          objects.map((o, i) => {
            if (activeObject.id === o.id) {
              xob = o;
              pos = i;
            } else obsA.push(o);
          });

          if (xob) {
            var save = false;

            if (up) {
              if (pos < objects.length - 1) {
                pos++;
                save = true;
              }
            } else {
              if (pos > 0) {
                pos--;
                save = true;
              }
            }

            if (save) {
              var obsB = [];
              obsA.map((o, i) => {
                if (pos === i) {
                  obsB.push(xob);
                  save = false;
                }
                obsB.push(o);
              });

              if (save) {
                obsB.push(xob);
              }
              dispatch(setProjectObjects(obsB));
            }
          }
        }
      }
    }
  };

  if (activeObject === null) return <></>;

  return (
    <>
      <Row w={100} jc="space-around">
        <ButtonBase
          onClick={() => {
            hToggleLock();
          }}
        >
          {activeObject.locked ? (
            <LockIcon style={{ color: "#34a", fontSize: 22 }} />
          ) : (
            <LockOpenIcon style={{ color: "#892", fontSize: 22 }} />
          )}
        </ButtonBase>

        <ButtonBase
          className={classes.btn}
          onClick={() => {
            hMoveObject(true);
          }}
        >
          <FlipToFrontIcon style={{ fontSize: 24, color: "#c80" }} />
        </ButtonBase>
        <ButtonBase
          className={classes.btn}
          onClick={() => {
            hMoveObject(false);
          }}
        >
          <FlipToBackIcon style={{ fontSize: 24, color: "#d90" }} />
        </ButtonBase>
      </Row>

      <Row w={100}>
        <ZoomInIcon style={{ fontSize: 24 }} />
        <Slider
          className={classes.control}
          size="small"
          step={2}
          min={5}
          max={100}
          value={activeObject.props.zoom}
          onChange={hChangeZoom}
        />
      </Row>

      <Row w={85}>
        <OpacityIcon style={{ fontSize: 22, color: "#c80" }} />
        <input
          className={classes.control}
          name="op"
          type="number"
          min={1}
          max={100}
          value={activeObject.props.op}
          onChange={hChangeOp}
        />
      </Row>

      <Row w={85}>
        <input
          name="visible"
          type="checkbox"
          checked={activeObject.props.visible}
          onChange={hChangeChk}
        />
        <VisibilityIcon style={{ fontSize: 22, color: "#c80" }} />
      </Row>

      <Row w={85}>
        <input
          name="flip"
          type="checkbox"
          checked={activeObject.props.flip}
          onChange={hChangeChk}
        />
        <FlipIcon style={{ fontSize: 22, color: "#c80" }} />
      </Row>

      <Row w={85}>
        <RotateLeftIcon />
        <input
          className={classes.control}
          name="ang"
          type="number"
          min={0}
          max={360}
          value={activeObject.props.ang}
          onChange={hChangeAng}
        />
      </Row>

      <Row w={85}>
        <span style={{ width: "30px", textAlign: "center" }}>X</span>
        <input
          className={classes.control}
          name="x"
          type="number"
          min={1}
          max={10000}
          value={activeObject.props.x}
          onChange={hChangePrps}
        />
      </Row>

      <Row w={85}>
        <span style={{ width: "30px", textAlign: "center" }}>Y</span>
        <input
          className={classes.control}
          name="y"
          type="number"
          min={1}
          max={10000}
          value={activeObject.props.y}
          onChange={hChangePrps}
        />
      </Row>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default GlobalProps;
