import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_MOVE_DAT_OP1"] = "Angle";
  Blockly.Msg["S_MOVE_DAT_OP2"] = "Zoom";
  Blockly.Msg["S_MOVE_DAT_OP3"] = "Opacity";

  Blockly.Msg["S_MOVE_STP_M1"] = "Move forward";
  Blockly.Msg["S_MOVE_STP_M2"] = "steps";

  Blockly.Msg["S_SHOW_FRAME_M1"] = "Show Frame";
  Blockly.Msg["S_NEXT_FRAME_M1"] = "Next Frame";

  Blockly.Msg["S_MOVE_TIME_M1"] = "Move ";
  Blockly.Msg["S_MOVE_TIME_M2"] = "steps with angle";

  Blockly.Msg["S_MOVE_OP1"] = "Move";
  Blockly.Msg["S_MOVE_OP2"] = "Walk";

  Blockly.Msg["S_SET_XY_M1"] = "Position";

  Blockly.Msg["S_GOTO_M1"] = "Goto";
};

export default loadMsgs;
