import React from "react";
import { HexColorPicker } from "react-colorful";
import { makeStyles } from "@mui/styles";
import { ButtonBase } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { FILL_COLOR, STROKE_COLOR, TEXT_COLOR } from "../../project/defines";

import CModal from "../../tools/CModal";
import { Column, Row } from "./PropeLayout";

import { useSelector } from "react-redux";

const ColorProps = ({ update, isTxt, isFill, isBorder }) => {
  const classes = useStyles();
  const { activeObject } = useSelector((s) => s.control);
  const [color, setColor] = React.useState(null);

  const hChangeColor = () => {
    var prps = null;
    if (color[0] === "tc") {
      prps = { ...activeObject.props, tc: color[1] };
    } else if (color[0] === "lc") {
      prps = { ...activeObject.props, lc: color[1] };
    } else if (color[0] === "fc") {
      prps = { ...activeObject.props, fc: color[1] };
    }

    if (prps) {
      const obx = { ...activeObject, props: prps };
      update(obx);
    }

    setColor(null);
  };

  const hChangeEnable = (ev) => {
    var prps = null;
    if (ev.target.name === "tc") {
      prps = {
        ...activeObject.props,
        tc: ev.target.checked ? TEXT_COLOR : null,
      };
    } else if (ev.target.name === "lc") {
      prps = {
        ...activeObject.props,
        lc: ev.target.checked ? STROKE_COLOR : null,
      };
    } else if (ev.target.name === "fc") {
      prps = {
        ...activeObject.props,
        fc: ev.target.checked ? FILL_COLOR : null,
      };
    }

    if (prps) {
      const obx = { ...activeObject, props: prps };
      update(obx);
    }
  };

  const colorBox = (itm, name) => {
    if (itm === undefined) return <></>;

    return (
      <Row w={80}>
        <input
          name={name}
          type="checkbox"
          checked={itm !== null}
          onChange={hChangeEnable}
        />
        <div
          className={classes.colorBx}
          style={{
            backgroundColor: itm ? itm : "#eee",
          }}
          onClick={() => {
            if (itm) setColor([name, itm]);
          }}
        ></div>
        {name === "tc" && (
          <FormatColorTextIcon style={{ fontSize: 20, color: "#444" }} />
        )}
        {name === "lc" && (
          <BorderColorIcon style={{ fontSize: 20, color: "#444" }} />
        )}
        {name === "fc" && (
          <FormatColorFillIcon style={{ fontSize: 20, color: "#444" }} />
        )}
      </Row>
    );
  };

  if (activeObject.props) {
    return (
      <>
        {isTxt && colorBox(activeObject.props.tc, "tc")}
        {isBorder && colorBox(activeObject.props.lc, "lc")}
        {isFill && colorBox(activeObject.props.fc, "fc")}

        <CModal open={color ? true : false} close={() => {}}>
          <Column>
            <Row w={120}>
              <div className={classes.picker}>
                {color && (
                  <HexColorPicker
                    color={color[1]}
                    onChange={(c) => {
                      setColor([color[0], c]);
                    }}
                  />
                )}
              </div>
            </Row>

            <Row w={120}>
              {color && (
                <div
                  style={{
                    position: "relative",
                    display: "block",
                    border: "1px solid #eee",
                    width: "100%",
                    height: "50px",
                    margin: 3,
                    backgroundColor: color[1],
                  }}
                ></div>
              )}
            </Row>

            <Row w={120} jc="space-around">
              <ButtonBase
                onClick={() => {
                  hChangeColor();
                }}
              >
                <CheckCircleIcon style={{ color: "#293", fontSize: 30 }} />
              </ButtonBase>
              <ButtonBase
                onClick={() => {
                  setColor(null);
                }}
              >
                <CancelIcon style={{ color: "#434", fontSize: 30 }} />
              </ButtonBase>
            </Row>
          </Column>
        </CModal>
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  picker: {
    position: "relative",
    display: "block",
    width: "100%",
    height: "80px",
    "& .react-colorful": {
      width: "100%",
      height: "100%",
    },
    "& .react-colorful__hue": {
      height: "10px",
    },
    "& .react-colorful__pointer": {
      width: "15px",
      height: "15px",
    },
    "& .react-colorful__saturation-pointer": {
      width: "15px",
      height: "15px",
    },
  },
  colorBx: {
    border: "1px solid #eee",
    width: "15px",
    height: "15px",
    margin: 3,
  },
  modal: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    padding: "5px 5px",
  },
}));

export default ColorProps;
