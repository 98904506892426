import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_LOOP_FOREVER_M1"] = "تكرار دائم";

  Blockly.Msg["S_LOOP_WHILE_M1"] = "طالما";
  Blockly.Msg["S_LOOP_DO_M1"] = "كرر";

  Blockly.Msg["S_LOOP_COUNT_M1"] = "تكرار";
  Blockly.Msg["S_LOOP_COUNT_M2"] = "مرات";

  Blockly.Msg["S_LOOP_CONTINUE_M1"] = "متابعة";

  Blockly.Msg["S_LOOP_BREAK_M1"] = "خروج";
};

export default loadMsgs;
