import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_TCOLOR_M1"] = "لون النص";
  Blockly.Msg["S_TCOLOR_TT"] = "لون النص";

  Blockly.Msg["S_LCOLOR_M1"] = "لون الإطار";
  Blockly.Msg["S_LCOLOR_TT"] = "لون الإطار";

  Blockly.Msg["S_FCOLOR_M1"] = "لون التعبئة";
  Blockly.Msg["S_FCOLOR_TT"] = "لون التعبئة";
};

export default loadMsgs;
