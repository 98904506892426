import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  buildNGon,
  buildXRect,
  buildTriangle,
  buildTrapezium,
  buildArrow,
} from "./ShapesBuilder";

import CModal from "../tools/CModal";
import { loadShapesObjects } from "./ObjectsLoader";
import { genID, InitWorkspace, InitShape } from "./ObjectsOperations";

import { useSelector, useDispatch } from "react-redux";
import { setObModalStatus } from "../data/editor";
import { setProjectWorkspaces, setProjectObjects } from "../data/project";

const ShapesObjectsModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { obModalStatus } = useSelector((s) => s.editor);
  const { objects, workspaces } = useSelector((s) => s.project);

  const [shapeList, setShapeList] = useState(null);

  const closeModal = () => {
    dispatch(setObModalStatus(0));
  };

  const addObject = (ob) => {
    var tmpObj = null;
    const id = genID("SHP");

    var xParams = null;
    var xPath = null;

    if (ob.name === "arc") {
      xParams = { alfa: ob.alfa, r: ob.r };
      tmpObj = InitShape(id, ob.name, ob.name, ob.thumb, xParams, null);
    } else if (ob.name === "oval") {
      xParams = { a: ob.a, b: ob.b };
      tmpObj = InitShape(id, ob.name, ob.name, ob.thumb, xParams);
    } else if (ob.name === "arrow") {
      xParams = { a: ob.a, b: ob.b };
      xPath = buildArrow(xParams);
    } else if (ob.name === "triangle") {
      xParams = { a: ob.a, b: ob.b, alfa: ob.alfa };
      xPath = buildTriangle(xParams);
    } else if (ob.name === "gon") {
      xParams = { n: ob.n, r: ob.r };
      xPath = buildNGon(xParams);
    } else if (ob.name === "rect") {
      xParams = { a: ob.a, b: ob.b, alfa: ob.alfa };
      xPath = buildXRect(xParams);
    } else if (ob.name === "trapezium") {
      xParams = { a: ob.a, b: ob.b, h: ob.h };
      xPath = buildTrapezium(xParams);
    }

    if (xPath) {
      tmpObj = InitShape(id, "path", ob.name, ob.thumb, xParams, xPath);
    }

    if (tmpObj) {
      var objs = [...objects, tmpObj];

      var ws = [...workspaces, InitWorkspace(id)];

      dispatch(setProjectObjects(objs));
      dispatch(setProjectWorkspaces(ws));
    }

    closeModal();
  };

  useEffect(() => {
    const lst = loadShapesObjects();
    setShapeList(lst);
  }, []);

  return (
    <CModal open={obModalStatus === 2} close={closeModal}>
      <div className={classes.root}>
        <div className={classes.group}>
          {Array.isArray(shapeList) &&
            shapeList.map((ob) => (
              <div
                key={ob.name}
                className={classes.item}
                onClick={() => {
                  addObject(ob);
                }}
              >
                <img crossOrigin="anonymous" alt="" src={ob.thumb} />
              </div>
            ))}
        </div>
      </div>
    </CModal>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "100%",
    height: "100%",
    maxWidth: "500px",
    overflowY: "scroll",
    padding: theme.spacing(1),
  },
  group: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    border: "1px solid #ccc",
    width: "100%",
    padding: theme.spacing(1),
  },
  item: {
    border: "1px solid #eee",
    borderRadius: "5px",
    width: "60px",
    height: "60px",
    padding: "3px",
    margin: "6px",
    cursor: "pointer",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
}));

export default ShapesObjectsModal;
