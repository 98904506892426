const toolbox = (lang) => {
  let name = "";
  if (lang == "ar") {
    name = "التكرار";
  } else {
    name = "Loops";
  }

  return (
    `<category categorystyle="loops_category" name="` +
    name +
    `">
    <block type="loop_forever"></block>
    <block type="loop_count">
      <field name="cnt">10</field>
    </block>
    <block type="loop_while_do">
      <value name="xin">
        <shadow type="logic_val">
          <field name="val">1</field>
        </shadow>
      </value>
    </block>
    <block type="loop_do_while">
      <value name="xin">
        <shadow type="logic_val">
          <field name="val">1</field>
        </shadow>
      </value>
    </block>
    <block type="loop_continue"></block>
    <block type="loop_break"></block>
  </category>`
  );
};

export default toolbox;
