import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import { ButtonBase } from "@mui/material";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";

import TextDialog from "../../tools/TextDialog";
import { Column, Row } from "./PropeLayout";
import { buildText } from "../../project/ShapesBuilder";

import { useSelector } from "react-redux";

const TextProps = ({ update }) => {
  const classes = useStyles();
  const { activeObject, ctxElement } = useSelector((s) => s.control);
  const texts = useSelector((s) => s.settings.texts.designer);

  const [open, setOpen] = useState(false);

  const hSetAllign = (x) => {
    const params = { ...activeObject.params, allign: x };
    const obx = { ...activeObject, params: params };
    update(obx);
  };

  const hSetFill = (ev) => {
    const props = { ...activeObject.props, fill: ev.target.checked };
    const obx = { ...activeObject, props: props };
    update(obx);
  };

  const hSave = (xTxt) => {
    const params = buildText(ctxElement.getContext("2d"), xTxt);
    const obx = { ...activeObject, params: params, txt: xTxt };
    update(obx);
  };

  const hChangeVar = (ev) => {
    const props = {
      ...activeObject.props,
      vn: ev.target.value.replace(/\s+/g, ""),
    };
    const obx = { ...activeObject, props: props };
    update(obx);
  };

  var txtDir = "left";

  if (activeObject.params.allign === 1) {
    txtDir = "center";
  } else if (activeObject.params.allign === 2) {
    txtDir = "right";
  }

  if (activeObject.params) {
    return (
      <>
        <Row>
          <input
            name={"fill"}
            type="checkbox"
            checked={activeObject.props.fill}
            onChange={hSetFill}
          />
          {activeObject.props.fill ? (
            <TurnedInIcon
              style={{
                fontSize: 25,
                color: "#44A",
              }}
            />
          ) : (
            <TurnedInNotIcon
              style={{
                fontSize: 25,
                color: "#44A",
              }}
            />
          )}
        </Row>

        <Column w={100}>
          <Row w="100%">
            <input
              className={classes.control}
              name="a"
              type="text"
              placeholder={texts.variable}
              value={activeObject.props.vn ? activeObject.props.vn : ""}
              style={{
                textAlign: txtDir,
                overflow: "hidden",
                readOnly: true,
              }}
              onChange={hChangeVar}
            />
          </Row>

          <Row w="100%">
            <input
              className={classes.control}
              type="text"
              readOnly
              placeholder={texts.txt}
              style={{
                textAlign: txtDir,
                overflow: "hidden",
                readOnly: true,
              }}
              value={activeObject.txt ? activeObject.txt : ""}
              onClick={() => {
                setOpen(true);
              }}
            />
          </Row>

          <Row w="100%" jc="space-between">
            <ButtonBase
              onClick={() => {
                hSetAllign(0);
              }}
            >
              <FormatAlignLeftIcon
                style={{
                  fontSize: 25,
                  color: activeObject.params.allign === 0 ? "#44A" : "#555",
                }}
              />
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                hSetAllign(1);
              }}
            >
              <FormatAlignCenterIcon
                style={{
                  fontSize: 25,
                  color: activeObject.params.allign === 1 ? "#25B" : "#666",
                }}
              />
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                hSetAllign(2);
              }}
            >
              <FormatAlignRightIcon
                style={{
                  fontSize: 25,
                  color: activeObject.params.allign === 2 ? "#25B" : "#666",
                }}
              />
            </ButtonBase>
          </Row>

          <TextDialog
            open={open}
            close={() => {
              setOpen(false);
            }}
            txt={activeObject.txt}
            setTxt={(x) => {
              hSave(x);
              setOpen(false);
            }}
          />
        </Column>
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default TextProps;
