import actionsCategory from "./category/actions";
import moveCategory from "./category/move";

const ImageObjectBlocks = (lng) => {
  let tbx = moveCategory(lng);
  tbx += actionsCategory(lng);

  return tbx;
};

export default ImageObjectBlocks;
