import { TEXT_SIZE } from "../project/defines";

export const buildNGon = ({ n, r }) => {
  const px = [];
  const a = (2 * Math.PI) / n;

  for (var i = 0; i < n; i++) {
    const x = Math.round(r * Math.cos(a * i) * 100) / 100;
    const y = Math.round(r * Math.sin(a * i) * 100) / 100;
    px.push([x, y]);
  }

  return [px];
};

export const buildXRect = ({ a, b, alfa }) => {
  const radAlfa = (alfa * Math.PI) / 180;

  var w = 0;
  var h = 0;
  const px = [];
  var dx = Math.round(a * Math.cos(radAlfa) * 10) / 10;
  if (dx >= 0) {
    dx = Math.abs(dx);
    w = Math.round(5 * (b + dx)) / 10;
    h = -Math.round(5 * a * Math.sin(radAlfa)) / 10;

    px.push([-w, -h]);
    px.push([b - w, -h]);

    px.push([w, h]);
    px.push([dx - w, h]);
  } else {
    dx = Math.abs(dx);
    w = Math.round((b + dx) * 5) / 10;
    h = -Math.round(a * Math.sin(radAlfa) * 5) / 10;

    px.push([-w + dx, -h]);
    px.push([w, -h]);

    px.push([w - dx, h]);
    px.push([-w, h]);
  }

  return [px];
};

export const buildTriangle = ({ a, b, alfa }) => {
  const radAlfa = (alfa * Math.PI) / 180;
  const px = [];
  var w = 0;

  var x = Math.round(10 * a * Math.cos(radAlfa)) / 10;
  var y = -Math.round(5 * a * Math.sin(radAlfa)) / 10;

  if (x < 0) {
    x = Math.abs(x);
    w = Math.abs(b + x) / 2;

    px.push([-w + x, -y]);
    px.push([w, -y]);
    px.push([-w, y]);
  } else if (x > b) {
    w = Math.abs(x) / 2;
    px.push([-w, -y]);
    px.push([b - w, -y]);
    px.push([w, y]);
  } else {
    w = b / 2;
    px.push([-w, -y]);
    px.push([w, -y]);
    px.push([x - w, y]);
  }

  return [px];
};

export const buildTrapezium = ({ a, b, h }) => {
  const px = [];

  var ia = Math.abs(Math.round(5 * a) / 10);
  var ib = Math.abs(Math.round(5 * b) / 10);
  var ih = -Math.abs(Math.round(5 * h) / 10);

  px.push([-ib, -ih]);
  px.push([ib, -ih]);
  px.push([ia, ih]);
  px.push([-ia, ih]);

  return [px];
};

export const buildArrow = ({ a, b }) => {
  const x = a / 2;
  const y = b / 2;

  const px1 = [];
  px1.push([-x, -y]);
  px1.push([x, -y]);
  px1.push([x, y]);
  px1.push([-x, y]);

  const px2 = [];
  px2.push([x, -b]);
  px2.push([x + b, 0]);
  px2.push([x, b]);

  return [px1, px2];
};

export const buildText = (ctx, txt) => {
  ctx.font = TEXT_SIZE + "px Arial";
  ctx.textBaseline = "middle";

  const lines = txt.split("\n");

  var rx = 0;

  for (var i = 0; i < lines.length; i++) {
    var m = ctx.measureText(lines[i]);

    const w = Math.round(m.width);

    if (rx < w) {
      rx = w;
    }
  }

  const w = rx / 2;
  rx = 15 + w;

  const h = Math.round(TEXT_SIZE / 2);

  var ry = 15 + lines.length * h;

  return { w: w, h: h, rx: rx, ry: ry, allign: 1 };
};
