import React from "react";

import { makeStyles } from "@mui/styles";
import { ButtonBase } from "@mui/material";
import ExtensionIcon from "@mui/icons-material/Extension";
import ExtensionIconOutlined from "@mui/icons-material/ExtensionOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import {
  genID,
  genPosition,
  InitWorkspace,
} from "../../project/ObjectsOperations";
import { Row } from "./PropeLayout";

import { useSelector, useDispatch } from "react-redux";
import { setActiveObject } from "../../data/control";
import { setActiveTask } from "../../data/editor";
import {
  setProjectObjects,
  setProjectTasks,
  setProjectWorkspaces,
} from "../../data/project";

const ObjectThumb = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTask } = useSelector((s) => s.editor);
  const { activeObject } = useSelector((s) => s.control);
  const { objects, tasks, workspaces } = useSelector((s) => s.project);

  const hCloneObject = () => {
    if (activeObject) {
      if (Array.isArray(objects)) {
        const id = genID("EXT");

        const xPos = genPosition();
        const prp = { ...activeObject.props, x: xPos[0], y: xPos[1] };
        const txtOb = JSON.stringify({ ...activeObject, id: id, props: prp });
        try {
          const xOb = JSON.parse(txtOb);
          const objs = [...objects, xOb];
          dispatch(setProjectObjects(objs));

          var xws = InitWorkspace(id);
          workspaces.map((w) => {
            if (w.id === activeObject.id) {
              xws.data = w.data;
            }
          });
          dispatch(setProjectWorkspaces([...workspaces, xws]));

          var xtsk = [];
          tasks.map((t) => {
            if (t.id === activeObject.id) {
              xtsk.push({ ...t, id: id });
            }
          });
          dispatch(setProjectTasks([...tasks, ...xtsk]));
        } catch (error) {}
      }
    }
  };

  const hDeleteObject = () => {
    if (activeObject) {
      if (Array.isArray(objects)) {
        var xId = null;
        var tOb = null;

        if (objects.length > 0) {
          const objs = [];
          objects.forEach((o) => {
            if (activeObject.id !== o.id) {
              objs.push(o);
              if (tOb === null) {
                tOb = o;
              }
              if (xId === null) {
                if (o.isTask && activeTask !== o.id) {
                  xId = o.id;
                }
              }
            }
          });
          dispatch(setProjectObjects(objs));
        }

        if (tasks.length > 0) {
          var tmpTasks = [];
          tasks.forEach((tsk) => {
            if (activeObject.id !== tsk.id) tmpTasks.push(tsk);
          });
          dispatch(setProjectTasks(tmpTasks));
        }

        if (workspaces.length > 0) {
          var tmpWs = [];
          workspaces.forEach((ws) => {
            if (activeObject.id !== ws.id) tmpWs.push(ws);
          });
          dispatch(setProjectWorkspaces(tmpWs));
        }

        if (activeTask === activeObject.id) {
          dispatch(setActiveTask(xId));
        }

        dispatch(setActiveObject(tOb));
      }
    }
  };

  const hToggleTask = () => {
    if (activeObject) {
      if (Array.isArray(objects)) {
        if (objects.length > 0) {
          var xId = null;
          const obs = objects.map((o) => {
            if (activeObject.id === o.id) {
              var xob = null;
              if (activeObject.isTask) {
                xob = { ...o, isTask: false };
              } else {
                xId = o.id;
                xob = { ...o, isTask: true };
              }

              dispatch(setActiveObject(xob));
              return xob;
            }

            if (o.isTask && xId === null) {
              xId = o.id;
            }
            return o;
          });

          dispatch(setProjectObjects(obs));
          dispatch(setActiveTask(xId));
        }
      }
    }
  };

  const hNextObject = (up) => {
    if (activeObject) {
      if (Array.isArray(objects)) {
        if (objects.length > 0) {
          var ob1 = null;
          var ob2 = null;
          var ob3 = null;
          objects.map((o) => {
            if (ob2 !== null && ob3 === null) {
              ob3 = o;
            }
            if (activeObject.id === o.id) {
              ob2 = o;
            }
            if (ob2 === null) {
              ob1 = o;
            }
          });

          if (up && ob3) {
            dispatch(setActiveObject(ob3));
            if (ob3.isTask) dispatch(setActiveTask(ob3.id));
          } else if (up === false && ob1) {
            dispatch(setActiveObject(ob1));
            if (ob1.isTask) dispatch(setActiveTask(ob1.id));
          }
        }
      }
    }
  };

  if (activeObject === null) return <></>;

  return (
    <Row w={100}>
      <img
        crossOrigin="anonymous"
        alt=""
        className={classes.thumpImg}
        src={activeObject.thumb}
      />
      <div className={classes.thumpCtrls}>
        <Row jc="space-between">
          <ButtonBase
            onClick={() => {
              hToggleTask();
            }}
          >
            {activeObject.isTask ? (
              <ExtensionIcon style={{ color: "#273", fontSize: 20 }} />
            ) : (
              <ExtensionIconOutlined style={{ color: "#555", fontSize: 20 }} />
            )}
          </ButtonBase>

          <ButtonBase
            onClick={() => {
              hCloneObject();
            }}
          >
            <FileCopyIcon style={{ color: "#279", fontSize: 20 }} />
          </ButtonBase>

          <ButtonBase
            onClick={() => {
              hDeleteObject();
            }}
          >
            <DeleteForeverIcon style={{ color: "#F22", fontSize: 20 }} />
          </ButtonBase>
        </Row>
      </div>

      <div className={classes.thumpNav}>
        <Row w={100} jc="space-around">
          <ButtonBase
            onClick={() => {
              hNextObject(true);
            }}
          >
            <SkipNextIcon
              style={{
                color: "#288",
                transform: "rotate(180deg)",
                fontSize: 28,
              }}
            />
          </ButtonBase>

          <ButtonBase
            onClick={() => {
              hNextObject(false);
            }}
          >
            <SkipNextIcon style={{ color: "#288", fontSize: 28 }} />
          </ButtonBase>
        </Row>
      </div>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  thumpImg: {
    objectFit: "contain",
    width: "100%",
    height: "80px",
    opacity: 0.8,
  },
  thumpNav: {
    position: "absolute",
    display: "block",
    bottom: 0,
    left: 0,
    width: "100%",
    borderRadius: "5px",
    backgroundColor: "rgb(220,220,220,0.7)",
    padding: "2px 3px",
  },
  thumpCtrls: {
    position: "absolute",
    display: "block",
    top: 0,
    left: 0,
    width: "100%",
    borderRadius: "5px",
    backgroundColor: "rgb(220,220,220,0.7)",
    padding: "2px 3px",
  },
}));

export default ObjectThumb;
