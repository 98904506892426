import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_VAR_M1"] = "المتغير";
  Blockly.Msg["S_SUPER_M1"] = "المتغير العام";
  Blockly.Msg["S_TIME_M1"] = "الزمن";

  Blockly.Msg["S_CONST_OP1"] = "جذر 2";
  Blockly.Msg["S_CONST_OP2"] = "جذر 0.5";

  Blockly.Msg["S_CMP_OP1"] = ">";
  Blockly.Msg["S_CMP_OP2"] = ">=";
  Blockly.Msg["S_CMP_OP3"] = "<";
  Blockly.Msg["S_CMP_OP4"] = "<=";
  Blockly.Msg["S_CMP_OP5"] = "==";
  Blockly.Msg["S_CMP_OP6"] = "=!";

  Blockly.Msg["S_CALCS_OP1"] = "جذر";
  Blockly.Msg["S_CALCS_OP2"] = "القيمة المطلقة";
  Blockly.Msg["S_CALCS_OP3"] = "لوغاريتم طبيعي";
  Blockly.Msg["S_CALCS_OP4"] = "لوغاريتم عشري";
};

export default loadMsgs;
