import {
  OBTYPE_IMAGE,
  OBTYPE_SHAPE,
  OBTYPE_TEXT,
  FILL_COLOR,
  STROKE_COLOR,
  TEXT_COLOR,
} from "./defines";

export const genID = (pfx) => {
  const id =
    Math.round(Math.random() * 100).toString(16) +
    Math.round(Date.now()).toString(16);

  if (pfx) {
    const x = pfx + "_" + id.toString();
    return x.replace(/\W/g, "");
  }

  return id.toString();
};

export const genPosition = () => {
  var x = 0;
  var y = 0;
  while (x < 70 || y < 70) {
    x = Math.round(Math.random() * 200);
    y = Math.round(Math.random() * 200);
  }
  return [x, y];
};

export const InitWorkspace = (id) => {
  var ws = {
    id: id,
    data: '<xml><block type="task_start" deletable="false"></block></xml>',
  };

  return ws;
};

export const InitImgObject = (id, category, name, img, thumb, params) => {
  const pos = genPosition();
  const ob = {
    id: id,
    isTask: false,
    locked: false,
    type: OBTYPE_IMAGE,
    category: category,
    name: name,
    thumb: thumb,
    img: img,
    params: params,
    props: {
      visible: true,
      flip: false,
      x: pos[0],
      y: pos[1],
      ang: 0,
      zoom: 50,
      op: 100,
      msg: "",
      speed: 1,
      fn: 0,
      tc: TEXT_COLOR,
      fc: null,
      lc: null,
    },
  };

  return ob;
};

export const InitTextObject = (id, txt, params) => {
  const pos = genPosition();
  const ob = {
    id: id,
    isTask: false,
    locked: false,
    type: OBTYPE_TEXT,
    category: "TXT",
    name: "TXT",
    thumb: "/img/obj/text-box.png",
    txt: txt,
    params: params,
    props: {
      visible: true,
      flip: false,
      x: pos[0],
      y: pos[1],
      ang: 0,
      zoom: 50,
      op: 100,
      msg: "",
      fill: true,
      vn: null,
      tc: TEXT_COLOR,
      fc: null,
      lc: null,
    },
  };

  return ob;
};

export const InitShape = (id, category, name, thumb, params, path) => {
  const pos = genPosition();
  const ob = {
    id: id,
    isTask: false,
    locked: false,
    type: OBTYPE_SHAPE,
    category: category,
    name: name,
    thumb: thumb,
    params: params,
    path: path,
    props: {
      visible: true,
      flip: false,
      x: pos[0],
      y: pos[1],
      ang: 0,
      zoom: 50,
      op: 100,
      msg: "",
      tc: TEXT_COLOR,
      fc: FILL_COLOR,
      lc: STROKE_COLOR,
    },
  };

  return ob;
};
