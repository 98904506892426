import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import {
  getArgument,
  buildStmt,
  buildGroup,
  inputToParams,
  getStmtCount,
} from "../helpers";

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["task_start"] = function (block) {
    const stmt = Blockly.JavaScript.statementToCode(block, "stmt");

    var code =
      ',{"type":"task","name":"start","stp":0,"stmt":[{"type":"NA"}' +
      stmt +
      "]}";

    return code;
  };

  Blockly.JavaScript["task_on_click"] = function (block) {
    const stmt = Blockly.JavaScript.statementToCode(block, "stmt");
    const n = getStmtCount(stmt);
    if (n > 1) {
      const ss = buildStmt("basic", "waitClick", [0, 0, 0]);
      const gp = buildGroup("loop", [0, 999999, 0], ss + stmt);

      var code =
        ',{"type":"task","name":"click","stp":0,"stmt":[{"type":"NA"}' +
        gp +
        "]}";

      return code;
    }

    return "";
  };

  Blockly.JavaScript["task_on_msg"] = function (block) {
    const stmt = Blockly.JavaScript.statementToCode(block, "stmt");
    const n = getStmtCount(stmt);
    if (n > 1) {
      const txt = block.getFieldValue("txt");
      if (txt) {
        const ss = buildStmt("basic", "waitMsg", [txt, 0, 0]);
        const gp = buildGroup("loop", [0, 999999, 0], ss + stmt);

        var code =
          ',{"type":"task","name":"click","stp":0,"stmt":[{"type":"NA"}' +
          gp +
          "]}";

        return code;
      }
    }

    return "";
  };

  Blockly.JavaScript["task_on_time"] = function (block) {
    const stmt = Blockly.JavaScript.statementToCode(block, "stmt");
    const n = getStmtCount(stmt);
    if (n > 1) {
      var ts = block.getFieldValue("ts");
      if (ts > 0) {
        const x = ts * 1000;
        var ss = buildStmt("basic", "waitTime", ["d", x, 0, 0]);

        const gp = buildGroup("loop", [0, 999999, 0], stmt + ss);

        const code =
          ',{"type":"task","name":"timer","stp":0,"stmt":[{"type":"NA"}' +
          gp +
          "]}";

        return code;
      }
    }

    return "";
  };

  return toolbox(lang);
};

export default loadBlocks;
