import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";

const ThumbImg = () => {
  const classes = useStyles();
  const objects = useSelector((s) => s.project.objects);
  const { activeTask } = useSelector((s) => s.editor);

  const [imgThumb, setImgThumb] = useState(null);

  useEffect(() => {
    var img = null;
    if (Array.isArray(objects)) {
      objects.forEach((obj) => {
        if (obj.id === activeTask) {
          img = obj.thumb;
        }
      });
    }

    setImgThumb(img);
  }, [activeTask]);

  return (
    <div
      className={classes.root}
      style={{ display: imgThumb ? "block" : "none" }}
    >
      <div className={classes.wrapper}>
        {imgThumb && <img crossOrigin="anonymous" alt="" src={imgThumb} />}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "block",
    pointerEvents: "none",
    top: 0,
    width: "100%",
    padding: 10,
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    pointerEvents: "none",
    width: "100%",

    "& img": {
      objectFit: "contain",
      width: "50px",
      height: "50px",
      opacity: 0.5,
    },
  },
}));

export default ThumbImg;
