import React, { useRef, useEffect } from "react";

import { makeStyles } from "@mui/styles";

import { initGVBank, initMsgQue } from "../core/shared";
import LoadingView from "../tools/LoadingView";
import OverLayer from "./OverLayer";
import EditControls from "./EditControls";
import AudioManager from "./AudioManager";
import CtxScreen from "./CtxScreen";
import Controls from "./Controls";
import Painter from "./Painter";
import Engine from "../core/Engine";

import { useSelector } from "react-redux";

const EditWindow = () => {
  const classes = useStyles();
  const { objects, tasks, scrSize, type } = useSelector((s) => s.project);

  const [loading, setLoading] = React.useState(true);
  const xTasks = useRef([]);
  const xObjects = useRef([]);

  function copyData() {
    initGVBank();
    initMsgQue();

    xObjects.current = [];
    xTasks.current = [];

    if (Array.isArray(objects)) {
      objects.forEach((o) => {
        const ss = { ...o.props, clicks: 0, rotate: 0, vars: [] };
        var x = { ...o, status: ss };
        xObjects.current.push(x);
      });
    }

    if (Array.isArray(tasks)) {
      tasks.forEach((t) => {
        var x = { ...t, stp: 0, timer: 0 };
        xTasks.current.push(x);
      });
    }
  }

  useEffect(() => {
    copyData();
  }, [objects, tasks]);

  const printTopRuler = () => {
    var xx = [];
    for (var i = 0; i <= 1 + scrSize[0] / 25; i++) {
      if (i % 4 > 0) {
        xx.push(<div key={i.toString()} style={{ height: "8px" }}></div>);
      } else {
        xx.push(
          <div key={i.toString()} style={{ height: "16px" }}>
            {i * 25}
          </div>
        );
      }
    }
    return xx;
  };

  const printLeftRuler = () => {
    var xx = [];
    for (var i = 0; i <= 1 + scrSize[1] / 25; i++) {
      if (i % 4 > 0) {
        xx.push(<div key={i.toString()} style={{ width: "8px" }}></div>);
      } else {
        xx.push(
          <div key={i.toString()} style={{ width: "16px" }}>
            {i * 25}
          </div>
        );
      }
    }
    return xx;
  };

  return (
    <div className={classes.root} dir="ltr" tabIndex="0">
      <div className={classes.playAria}>
        <div className={classes.view}>
          <div style={{ left: 0 }} className={classes.topRuler}>
            {printTopRuler()}
          </div>
          <div style={{ left: 0 }} className={classes.leftRuler}>
            {printLeftRuler()}
          </div>
          <CtxScreen xObjects={xObjects} mg={0} />
          <OverLayer />
          <LoadingView open={type === null || loading} />
        </div>
      </div>

      <div className={classes.ctrlAria}>
        <Controls xPlugins={<EditControls />} />
      </div>

      <Engine xObjects={xObjects} xTasks={xTasks} copyData={copyData} />
      <Painter
        xObjects={xObjects}
        onLoad={() => {
          setLoading(false);
        }}
      />
      <AudioManager />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    backgroundColor: "#ececec",
    paddingBottom: "38px",
    height: "100%",
    width: "100%",
    padding: 1,
  },
  ctrlAria: {
    display: "block",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "38px",
  },
  playAria: {
    display: "block",
    position: "relative",
    backgroundColor: "#ddd",
    borderRadius: "0px",
    width: "100%",
    height: "100%",
  },
  view: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "21px",
    overflow: "scroll",

    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999",
      borderRadius: "3px",
    },
  },
  topRuler: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#ead",
    height: "20px",
    minWidth: "100%",
    margin: 0,
    paddingLeft: "21px",

    "& >div": {
      flexShrink: 0,
      width: "5px",
      marginRight: "20px",
      borderLeft: "1px solid #888",
      alignItems: "flex-start",
      fontSize: 10,
    },
  },
  leftRuler: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#ead",
    width: "20px",
    minHeight: "100%",
    margin: 0,
    paddingTop: "21px",

    "& >div": {
      flexShrink: 0,
      height: "5px",
      marginBottom: "20px",
      borderTop: "1px solid #888",
      alignItems: "flex-start",
      fontSize: 10,
    },
  },
}));

export default EditWindow;
