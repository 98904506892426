import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";

import { loadImageObjects } from "./ObjectsLoader";

const ImagesGallery = ({ show, addObject }) => {
  const classes = useStyles();

  const [imgList, setImgList] = useState(null);
  const [gpCnt, setGpCnt] = useState(1);
  const [page, setPage] = useState(1);

  const hChangePage = (ev, pg) => {
    setPage(pg);
  };

  useEffect(() => {
    const lst = loadImageObjects();
    if (Array.isArray(lst)) {
      setGpCnt(lst.length);
      setImgList(lst);
    }
  }, []);

  const showAll = () => {
    if (Array.isArray(imgList)) {
      if (page > 0 && page <= imgList.length) {
        const gp = imgList[page - 1];
        return (
          <div key={gp.name} className={classes.group}>
            {gp.data.map((ob) => (
              <div
                style={{
                  border: "1px solid " + (ob.fcnt > 1 ? "#0e0" : "#eee"),
                }}
                key={ob.name}
                className={classes.item}
                onClick={() => {
                  addObject(ob);
                }}
              >
                <img crossOrigin="anonymous" alt="" src={ob.thumb} />
              </div>
            ))}
          </div>
        );
      }
    }

    return <></>;
  };

  return (
    <div style={{ width: "100%", display: show ? "block" : "none" }}>
      <div key={"pags"} className={classes.group}>
        <div className={classes.lnk}>
          <Pagination
            count={gpCnt}
            page={page}
            onChange={hChangePage}
            color="secondary"
          />
        </div>
      </div>

      {showAll()}

      <div style={{ display: "none" }} key={"attr"} className={classes.group}>
        <div className={classes.lnk}>
          Images from
          <a target="_blank" href="http://www.freepik.com">
            Freepik
          </a>
          <a target="_blank" href="http://graphicSurf.com">
            DesignZone
          </a>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  group: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    border: "1px solid #ddd",
    borderRadius: "5px",
    width: "100%",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  item: {
    borderRadius: "5px",
    width: "60px",
    height: "60px",
    padding: "5px",
    margin: "3px",
    flexShrink: 0,

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  lnk: {
    color: "#444",
    fontSize: "12px",
    "& a": {
      color: "#444",
      textDecoration: "none",
      margin: "0px 3px",
    },
  },
}));

export default ImagesGallery;
