import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_WAIT_TIME_M1"] = "إنتظر";
  Blockly.Msg["S_WAIT_TIME_M2"] = "ميلي ثانية";

  Blockly.Msg["S_WAIT_CLICK_M1"] = "إنتظر النقر";

  Blockly.Msg["S_WAIT_MSG_M1"] = "إنتظر رسالة";
  Blockly.Msg["S_SEND_MSG_M1"] = "أرسل رسالة";

  Blockly.Msg["S_PLAY_SOUND_M1"] = "تشغيل صوت";

  Blockly.Msg["S_SAY_M1"] = "تكلم";
  Blockly.Msg["S_STOPTALK_M1"] = "توقف عن الكلام";

  Blockly.Msg["S_HIDE_ME_M1"] = "إختفاء";
  Blockly.Msg["S_SHOW_ME_M1"] = "ظهور";
};

export default loadMsgs;
