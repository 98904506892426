import React from "react";
import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

import { Row } from "./PropeLayout";

import { useSelector } from "react-redux";

const ImageProps = ({ update }) => {
  const classes = useStyles();
  const { activeObject } = useSelector((s) => s.control);

  const hChangeSpeed = (ev, value) => {
    const x = parseInt(value);
    const prp = { ...activeObject.props, speed: x };
    const obx = { ...activeObject, props: prp };
    update(obx);
  };

  const hChangeFn = (ev) => {
    if (ev.target.name === "fn") {
      var x = parseInt(ev.target.value);
      if (x < 0) {
        x = 0;
      } else if (x >= activeObject.params.fcnt) {
        x = activeObject.params.fcnt;
      }

      const prp = { ...activeObject.props, fn: x };
      const obx = { ...activeObject, props: prp };
      update(obx);
    }
  };

  if (activeObject.params) {
    if (activeObject.params.fcnt > 1) {
      return (
        <>
          <Row w={85}>
            <ViewCarouselIcon style={{ fontSize: 22, width: 30 }} />
            <input
              className={classes.control}
              name="fn"
              type="number"
              min={0}
              max={activeObject.params.fcnt - 1}
              value={activeObject.props.fn}
              onChange={hChangeFn}
            />
          </Row>
          <Row w={85}>
            <SpeedIcon style={{ fontSize: 22, width: 30 }} />
            <Slider
              className={classes.control}
              size="small"
              step={1}
              min={1}
              max={5}
              value={activeObject.props.speed}
              onChange={hChangeSpeed}
            />
          </Row>
        </>
      );
    }
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default ImageProps;
