import actionsCategory from "./category/actions";
import moveCategory from "./category/move";
import shapeCategory from "./category/shapes";

const ShapeObjectBlocks = (lng) => {
  let tbx = moveCategory(lng);
  tbx += actionsCategory(lng);
  tbx += shapeCategory(lng);

  return tbx;
};

export default ShapeObjectBlocks;
