import Blockly from "blockly";

import toolbox from "./toolbox";
import loadTexts from "./texts";
import blocks from "./blocks.json";

import {
  buildStmt,
  inputToParams,
  getArgument,
  txtToStmt,
  createVarName,
  varToStmt,
} from "../helpers";

const loadBlocks = (lang) => {
  loadTexts(lang);
  Blockly.defineBlocksWithJsonArray(blocks);

  Blockly.JavaScript["shape_color"] = function (block) {
    var code = "";
    if (block.getParent() !== null) {
      var c = block.getFieldValue("c");

      code = txtToStmt(c);
    }

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["shape_set_tcolor"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "setColor", ["t", ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["shape_set_lcolor"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "setColor", ["l", ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["shape_set_fcolor"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      if (xin !== null) {
        const prm = [];
        var code = inputToParams(xin, prm);

        if (prm.length > 0) {
          prm.push(0);
          code += buildStmt("action", "setColor", ["f", ...prm]);
          return code;
        }
      }
    }

    return "";
  };

  Blockly.JavaScript["shape_set_rgb"] = function (block) {
    if (block.getParent() !== null) {
      const xin = getArgument("xin", block);
      const yin = getArgument("yin", block);
      const zin = getArgument("zin", block);
      if (xin !== null && yin !== null && zin !== null) {
        const pms = [];
        const vName = createVarName();
        var code = varToStmt(vName);
        code += inputToParams(xin, pms);

        if (pms.length > 0) {
          code += inputToParams(yin, pms);

          if (pms.length > 2) {
            code += inputToParams(zin, pms);

            if (pms.length > 4) {
              code += buildStmt("action", "rgbToVar", [vName, ...pms]);
              return [code, Blockly.JavaScript.ORDER_NONE];
            }
          }
        }
      }
    }

    return ["", Blockly.JavaScript.ORDER_NONE];
  };

  return toolbox(lang);
};

export default loadBlocks;
