const toolbox = (lang) => {
  let name = "";

  if (lang == "ar") {
    name = "المنطق";
  } else {
    name = "Logic";
  }

  return (
    `<category categorystyle="logic_category" name="` +
    name +
    `">
    <block type="logic_val">
      <field name="val">1</field>
    </block>
    <block type="logic_not">
    <value name="xin">
      <shadow type="logic_val">
        <field name="val">1</field>
      </shadow>
    </value>
    </block>
    <block type="logic_if">  
    <value name="xin">
      <shadow type="logic_val">
        <field name="val">1</field>
      </shadow>
    </value>
    </block> 
    <block type="logic_if_else">  
    <value name="xin">
      <shadow type="logic_val">
        <field name="val">1</field>
      </shadow>
    </value>
    </block> 
    <block type="logic_operation">  
      <value name="xin">
        <shadow type="logic_val">
          <field name="val">1</field>
        </shadow>
      </value>
      <value name="yin">
        <shadow type="logic_val">
          <field name="val">1</field>
        </shadow>
      </value>
    </block> 
  </category>`
  );
};

export default toolbox;
