import Blockly from "blockly";

const loadMsgs = () => {
  Blockly.Msg["S_IF_M1"] = "إذا";

  Blockly.Msg["S_ELSE_M1"] = "وإلا";

  Blockly.Msg["S_LOGIC_NOT_M1"] = "ليس";

  Blockly.Msg["S_LOGICVAL_OP1"] = "نعم";
  Blockly.Msg["S_LOGICVAL_OP2"] = "لا";
};

export default loadMsgs;
