import React from "react";
import { makeStyles } from "@mui/styles";
import HeightIcon from "@mui/icons-material/Height";

import { Row } from "./PropeLayout";

import { buildArrow } from "../../project/ShapesBuilder";

import { useSelector } from "react-redux";

const ArrowProps = ({ update }) => {
  const classes = useStyles();
  const { activeObject } = useSelector((s) => s.control);

  const hChange = (ev) => {
    if (ev.target.value > 0 && ev.target.value <= 1000) {
      if (ev.target.name === "a") {
        const prm = {
          ...activeObject.params,
          a: parseInt(ev.target.value),
        };
        const tmp = buildArrow(prm);
        if (tmp) {
          const obx = { ...activeObject, params: prm, path: tmp };
          update(obx);
        }
      } else if (ev.target.name === "b") {
        const prm = {
          ...activeObject.params,
          b: parseInt(ev.target.value),
        };
        const tmp = buildArrow(prm);
        if (tmp) {
          const obx = { ...activeObject, params: prm, path: tmp };
          update(obx);
        }
      }
    }
  };

  if (activeObject.params) {
    return (
      <>
        <Row w={85}>
          <HeightIcon
            style={{ transform: "rotate(90deg)", fontSize: 22, color: "#c80" }}
          />
          <input
            className={classes.control}
            name="a"
            type="number"
            min={1}
            max={1000}
            value={activeObject.params.a}
            onChange={hChange}
          />
        </Row>
        <Row w={85}>
          <HeightIcon style={{ fontSize: 22, color: "#c80" }} />
          <input
            className={classes.control}
            name="b"
            type="number"
            min={1}
            max={400}
            value={activeObject.params.b}
            onChange={hChange}
          />
        </Row>
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default ArrowProps;
