import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Paper,
  ButtonBase,
  LinearProgress,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DeleteBtn } from "../tools/IconBtn";

import { DIR_CDN_IMG } from "../data/adresses";
import UserAPI from "../api/userApi";

const Gallery = ({ show, user, addObject }) => {
  const classes = useStyles();

  const [imgList, setImgList] = useState([]);
  const [busy, setBusy] = useState(0);

  const [imgFile, setImgFile] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    if (!show) return;

    if (Array.isArray(imgList)) {
      if (imgList.length > 0) return;
    }

    setBusy(1);

    UserAPI.getObjImages(
      user,
      "img",
      (m) => {
        setBusy(0);
        const ss = [];
        m.forEach((itm) => {
          ss.push({
            id: itm.id,
            name: itm.name,
            size: [itm.rx, itm.ry],
            thumb: DIR_CDN_IMG + itm.url + "/tb_" + itm.name,
            img: DIR_CDN_IMG + itm.url + "/" + itm.name,
          });
        });
        setImgList(ss);
      },
      (x) => {
        setBusy(0);
      }
    );
  }, [show]);

  const hChangeFile = (event) => {
    const img = event.target.files[0];
    if (img) {
      setImgFile(img);
    }
  };

  const uploadFile = () => {
    if (busy > 0) return;

    if (imgFile) {
      setBusy(2);
      UserAPI.addObjImage(
        user,
        { img: imgFile },
        (m) => {
          setBusy(0);
          const ss = [
            {
              id: m.id,
              name: m.name,
              size: [m.rx, m.ry],
              thumb: DIR_CDN_IMG + m.url + "/tb_" + m.name,
              img: DIR_CDN_IMG + m.url + "/" + m.name,
            },
            ...imgList,
          ];

          setImgList(ss);
        },
        (x) => {
          setBusy(0);
        }
      );
    }
  };

  const hDelImg = (id) => {
    if (busy > 0) return;

    setBusy(3);
    UserAPI.delObjImage(
      user,
      id,
      (m) => {
        setBusy(0);
        const ss = [];
        imgList.map((itm) => {
          if (itm.id !== id) {
            ss.push(itm);
          }
        });

        setImgList(ss);
      },
      (x) => {
        setBusy(0);
      }
    );
  };

  const hInsertObj = (itm) => {
    const ob = {
      name: itm.id,
      size: itm.size,
      fcnt: 1,
      thumb: itm.thumb,
      img: itm.img,
    };
    addObject(ob);
  };

  const showImages = () => {
    if (Array.isArray(imgList)) {
      return imgList.map((itm) => (
        <Paper key={itm.id} className={classes.paper}>
          <ButtonBase
            className={classes.item}
            onClick={() => {
              hInsertObj(itm);
            }}
          >
            <img crossOrigin="anonymous" alt="" src={itm.thumb} />
          </ButtonBase>
          <div className={classes.dBtn}>
            <DeleteBtn
              onClick={() => {
                hDelImg(itm.id);
              }}
            />
          </div>
        </Paper>
      ));
    }
  };

  return (
    <div style={{ width: "100%", display: show ? "block" : "none" }}>
      <Stack spacing={2}>
        <div className={classes.form}>
          <div style={{ display: "none" }}>
            <input
              type="file"
              ref={inputRef}
              name="tmpImg"
              accept="image/*"
              onChange={hChangeFile}
            />
          </div>
          <button
            onClick={() => {
              if (inputRef.current) inputRef.current.click();
            }}
          >
            Browse
          </button>
          <input
            type="text"
            readOnly
            value={imgFile ? imgFile.name : ""}
            style={{ flexGrow: 1 }}
          />

          <IconButton
            color="secondary"
            disabled={imgFile === null}
            onClick={() => {
              uploadFile();
            }}
          >
            <CloudUploadIcon />
          </IconButton>
        </div>
        <div className={classes.prgrs}>
          {busy > 0 && <LinearProgress color="secondary" />}
        </div>

        <div dir="ltr" className={classes.group}>
          {showImages()}
        </div>
      </Stack>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  group: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    border: "1px solid #ddd",
    borderRadius: "5px",
    width: "100%",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  paper: {
    position: "relative",
    border: "1px solid #eee",
    borderRadius: "5px",
    paddingTop: "10px",
    paddingRight: "10px",
    paddingLeft: "3px",
    paddingBottom: "3px",
    margin: "5px",
  },
  prgrs: {
    borderTop: "1px solid #eee",
    width: "100%",
    height: "10px",
  },
  item: {
    "& img": {
      width: "60px",
      height: "60px",
      objectFit: "contain",
    },
  },
  dBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  form: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    width: "100%",
    padding: 0,

    "& >*": {
      margin: "0px 3px",
    },
  },
}));

export default Gallery;
