import React from "react";
import { makeStyles } from "@mui/styles";
import HeightIcon from "@mui/icons-material/Height";

import { buildTrapezium } from "../../project/ShapesBuilder";

import { Row } from "./PropeLayout";

import { useSelector } from "react-redux";

const TrapeziumProps = ({ update }) => {
  const classes = useStyles();
  const { activeObject } = useSelector((s) => s.control);

  const hChangeLength = (ev) => {
    var prm = null;
    if (ev.target.name === "a") {
      prm = {
        ...activeObject.params,
        a: parseInt(ev.target.value < 1000 ? ev.target.value : 1000),
      };
    } else if (ev.target.name === "b") {
      prm = {
        ...activeObject.params,
        b: parseInt(ev.target.value < 1000 ? ev.target.value : 1000),
      };
    } else if (ev.target.name === "h") {
      prm = {
        ...activeObject.params,
        h: parseInt(ev.target.value < 1000 ? ev.target.value : 1000),
      };
    }

    if (prm) {
      const tmp = buildTrapezium(prm);
      if (tmp) {
        const obx = { ...activeObject, params: prm, path: tmp };
        update(obx);
      }
    }
  };

  if (activeObject.params) {
    return (
      <>
        <Row w={80}>
          <HeightIcon
            style={{ transform: "rotate(90deg)", fontSize: 22, color: "#c80" }}
          />
          <input
            className={classes.control}
            name="a"
            type="number"
            min={1}
            max={1000}
            value={activeObject.params.a}
            onChange={hChangeLength}
          />
        </Row>
        <Row w={80}>
          <HeightIcon style={{ fontSize: 22, color: "#c80" }} />
          <input
            className={classes.control}
            name="h"
            type="number"
            min={1}
            max={1000}
            value={activeObject.params.h}
            onChange={hChangeLength}
          />
        </Row>
        <Row w={80}>
          <HeightIcon
            style={{ transform: "rotate(90deg)", fontSize: 22, color: "#c80" }}
          />
          <input
            className={classes.control}
            name="b"
            type="number"
            min={1}
            max={1000}
            value={activeObject.params.b}
            onChange={hChangeLength}
          />
        </Row>
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  control: {
    position: "relative",
    display: "block",
    margin: "0px 2px",
    width: "100%",
    flexGrow: 1,
  },
}));

export default TrapeziumProps;
