const toolbox = (lang) => {
  let name = "";
  if (lang === "ar") {
    name = "الحسابات";
  } else {
    name = "Math";
  }

  return (
    `<category categorystyle="math_category" name="` +
    name +
    `">
    <block type="math_set_var">
      <field name="vn">x</field>
      <value name="xin">
        <shadow type="math_num">
          <field name="num">0</field>
        </shadow>
      </value>
    </block>
    <block type="math_var">
      <field name="vn">x</field>
    </block>
    <block type="math_set_super">
      <field name="vn">g</field>
      <value name="xin">
        <shadow type="math_num">
          <field name="num">0</field>
        </shadow>
      </value>
    </block>
    <block type="math_super">
      <field name="vn">g</field>
    </block>
    <block type="math_inc_var">
      <field name="vn">x</field>
      <field name="op">inc</field> 
    </block> 
    <block type="math_inc_super">
      <field name="vn">g</field>
      <field name="op">inc</field> 
    </block> 
    <block type="math_num">
      <field name="num">0</field>
    </block>
    <block type="math_ang">
      <field name="ang">45</field>
    </block>
    <block type="math_time"></block>
    <block type="math_const">
      <field name="op">pi</field>
    </block>
    <block type="math_calc">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
      <value name="yin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
    </block>
    <block type="math_cmp">
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
      <value name="yin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
    </block>
    <block type="math_fx">
      <field name="op">abs</field>
      <value name="xin">
        <shadow type="math_num">
          <field name="num">1</field>
        </shadow>
      </value>
    </block>
    <block type="math_trigo">
      <field name="op">sin</field>
      <value name="xin">
        <shadow type="math_ang">
          <field name="ang">90</field>
        </shadow>
      </value>
    </block>
  </category>`
  );
};

export default toolbox;
