import React from "react";

import { ButtonBase, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const MoreVBtn = ({ onClick }) => {
  return (
    <ButtonBase onClick={onClick}>
      <MoreVertIcon
        style={{
          margin: 0,
          padding: "2px",
          color: "#444",
          fontSize: "26px",
          borderRadius: "50%",
          backgroundColor: "rgb(220,220,220,0.9)",
        }}
      />
    </ButtonBase>
  );
};

export const EditBtn = ({ onClick, busy }) => {
  if (busy) {
    return <CircularProgress size={25} />;
  }

  return (
    <ButtonBase onClick={onClick}>
      <EditIcon
        style={{
          margin: 0,
          padding: "3px",
          color: "#283",
          fontSize: "26px",
          borderRadius: "50%",
          backgroundColor: "rgb(220,220,220,0.9)",
        }}
      />
    </ButtonBase>
  );
};

export const DeleteBtn = ({ onClick, busy }) => {
  if (busy) {
    return <CircularProgress size={25} />;
  }

  return (
    <ButtonBase onClick={onClick}>
      <DeleteForeverIcon
        style={{
          margin: 0,
          padding: "3px",
          color: "#D00",
          fontSize: "26px",
          borderRadius: "50%",
          backgroundColor: "rgb(220,220,220,0.9)",
        }}
      />
    </ButtonBase>
  );
};
