import Blockly from "blockly";

export const setTheme = () => {
  if (Blockly.Themes.Schoode) return;
  Blockly.Themes.Schoode = Blockly.Theme.defineTheme("syra", {
    base: Blockly.Themes.Classic,
    categoryStyles: {
      tasks_category: {
        colour: "#A525B5",
      },
      loops_category: {
        colour: "#CC2255",
      },
      basic_category: {
        colour: "#007744",
      },
      logic_category: {
        colour: "#2255AA",
      },
      math_category: {
        colour: "#4575E5",
      },
      shapes_category: {
        colour: "#C58500",
      },
      move_category: {
        colour: "#C58500",
      },
      actions_category: {
        colour: "#85710d",
      },
    },
    blockStyles: {
      tasks_blocks: {
        colourPrimary: "#A525B5",
        colourSecondary: "#AA77EE",
        colourTertiary: "#CDB6E9",
      },
      loops_blocks: {
        colourPrimary: "#CC2255",
        colourSecondary: "#ff0000",
        colourTertiary: "#C5E5F5",
      },
      basic_blocks: {
        colourPrimary: "#007744",
        colourSecondary: "#E595F5",
        colourTertiary: "#C5E5F5",
      },
      logic_blocks: {
        colourPrimary: "#2255AA",
        colourSecondary: "#E595F5",
        colourTertiary: "#C5E5F5",
      },
      math_blocks: {
        colourPrimary: "#4575E5",
        colourSecondary: "#33AA00",
        colourTertiary: "#C5E5F5",
      },
      move_blocks: {
        colourPrimary: "#C58500",
        colourSecondary: "#F5D545",
        colourTertiary: "#C5E5F5",
      },
      shapes_blocks: {
        colourPrimary: "#C58500",
        colourSecondary: "#F5D545",
        colourTertiary: "#C5E5F5",
      },
      actions_blocks: {
        colourPrimary: "#85710d",
        colourSecondary: "#F5D545",
        colourTertiary: "#C5E5F5",
      },
    },
    componentStyles: {
      workspaceBackgroundColour: "#FFFFFF",
      toolboxBackgroundColour: "#EEE",
      toolboxForegroundColour: "#333",
      flyoutBackgroundColour: "#DDDDDD",
      flyoutForegroundColour: "#CCCCCC",
      flyoutOpacity: 1,
      scrollbarColour: "#ff0000",
      insertionMarkerColour: "#FFFFFF",
      insertionMarkerOpacity: 0.3,
      scrollbarOpacity: 0.4,
      cursorColour: "#d0d0d0",
      blackBackground: "#333",
    },
  });
};
