import React from "react";

import { buildText } from "./ShapesBuilder";
import TextDialog from "../tools/TextDialog";

import { genID, InitWorkspace, InitTextObject } from "./ObjectsOperations";

import { useSelector, useDispatch } from "react-redux";
import { setObModalStatus } from "../data/editor";
import { setProjectWorkspaces, setProjectObjects } from "../data/project";

const TextObjectsModal = () => {
  const dispatch = useDispatch();
  const { ctxElement } = useSelector((s) => s.control);
  const { obModalStatus } = useSelector((s) => s.editor);
  const { objects, workspaces } = useSelector((s) => s.project);

  const closeModal = () => {
    dispatch(setObModalStatus(0));
  };

  const hAddObject = (xTxt) => {
    if (xTxt.length < 1) return;

    const id = genID("TXT");

    const params = buildText(ctxElement.getContext("2d"), xTxt);

    const txOb = InitTextObject(id, xTxt, params);

    const objs = [...objects, txOb];

    const ws = [...workspaces, InitWorkspace(id)];

    dispatch(setProjectObjects(objs));
    dispatch(setProjectWorkspaces(ws));

    closeModal();
  };

  return (
    <TextDialog
      open={obModalStatus === 3}
      close={() => {
        closeModal();
      }}
      txt=""
      setTxt={(x) => {
        hAddObject(x);
        closeModal();
      }}
    ></TextDialog>
  );
};

export default TextObjectsModal;
