import React from "react";

import { ButtonBase, Typography } from "@mui/material";

import { useSelector } from "react-redux";

export const OkBTN = ({ onClick }) => {
  const text = useSelector((s) => s.settings.texts.site);

  return (
    <ButtonBase onClick={onClick}>
      <Typography variant="h6" color="inherit">
        {text.ok}
      </Typography>
    </ButtonBase>
  );
};

export const CancelBTN = ({ onClick }) => {
  const text = useSelector((s) => s.settings.texts.site);

  return (
    <ButtonBase onClick={onClick}>
      <Typography variant="h6" color="inherit">
        {text.cancel}
      </Typography>
    </ButtonBase>
  );
};
