import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { AppBar } from "@mui/material";

import MainLogo from "./header/MainLogo";
import MainMenu from "./header/MainMenu";
import MobileMenu from "./header/MobileMenu";

import { getNextLang } from "../data/lang/langManager";

import { useSelector, useDispatch } from "react-redux";
import { setLang } from "../data/settings";

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lang = useSelector((s) => s.settings.lang);

  const [nextLang, setNextLang] = useState(["", ""]);

  const changeLang = () => {
    dispatch(setLang(nextLang[0]));
  };

  useEffect(() => {
    if (lang) {
      const x = getNextLang(lang.name);
      setNextLang([x.name, x.title]);
    }
  }, [lang]);

  return (
    <AppBar position="static" className={classes.root}>
      <div className={classes.toolbar}>
        <MainLogo />

        <div className={classes.desktop}>
          <MainMenu chgLang={changeLang} langTxt={nextLang[1]} />
        </div>

        <div className={classes.mobile}>
          <MobileMenu chgLang={changeLang} langTxt={nextLang[1]} />
        </div>
      </div>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "50px",
    padding: "0px 8px",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
  mobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  desktop: {
    display: "block",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default Header;
